import { PharmacyUserDto } from '@digitalpharmacist/users-service-client-axios';
import { Avatar } from 'assets/components/avatar';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useLoginState } from './login-store';
import { Button } from 'assets/components/button';
import { LoginTestIDs } from './LoginTestIDs';
import { LoginForm } from './login-actions';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout';
import { login } from './login-actions';

import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { useNavigation } from '@react-navigation/native';
export const QuickLogin = ({ user }: { user: PharmacyUserDto }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status } = useLoginState();
  const [showAuth, setShowAuth] = useState<UserInfoExtendedProps>({
    ...user,
    isOpen: false,
  });
  const navigation = useNavigation<RootStackNavigationProp>();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: user.email,
      password: '',
    },
  });

  interface UserInfoExtendedProps extends PharmacyUserDto {
    isOpen: boolean;
  }

  const handleSubmit = async () => {
    login(methods.getValues());
  };

  const selectedUser = (user: UserInfoExtendedProps) => {
    setShowAuth({ ...user, isOpen: !user.isOpen });
  };

  return (
    <View
      style={[styles.container, showAuth.isOpen ? styles.selectedUser : {}]}
    >
      <TouchableOpacity onPress={() => selectedUser(showAuth)}>
        <View style={styles.userInfo}>
          <Avatar
            labelStyle={styles.initialsText}
            size={32}
            color={theme.palette.gray[100]}
            name={`${showAuth.firstName} ${showAuth.lastName}`}
          />
          <View style={{ justifyContent: 'space-between', marginLeft: 10 }}>
            <Text
              style={[
                styles.initialsText,
                { fontWeight: '700', lineHeight: 20 },
              ]}
            >
              {showAuth.firstName} {showAuth.lastName}
            </Text>
            <Text style={styles.email}>{showAuth.email}</Text>
          </View>
        </View>
      </TouchableOpacity>
      {showAuth.isOpen && (
        <Form methods={methods}>
          <Form.Row style={{ marginTop: theme.getSpacing(2) }}>
            <TextField
              style={styles.authInput}
              label={getText('password')}
              type="password"
              name="password"
              rules={{
                required: getText('password-is-required'),
              }}
              disabled={status === 'loading'}
              testID={LoginTestIDs.passwordInput}
            />
            <Button
              loading={status === 'loading'}
              logger={{
                id: 'login-button',
              }}
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              size="medium"
              style={styles.loginButton}
            >
              <Text style={styles.buttonText}>Login</Text>
            </Button>
          </Form.Row>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('reset-link');
            }}
          >
            <Text style={styles.forgotPasswordText}>
              {getText('forgot-password')}
            </Text>
          </TouchableOpacity>
        </Form>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginBottom: theme.getSpacing(2),
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 7,
    width: '90%',
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  email: {
    fontSize: 12,
    fontWeight: '400',
    color: theme.palette.gray[500],
  },
  initials: {
    height: 32,
    width: 32,
    borderRadius: 50,
    fleX: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.white,
  },
  initialsText: {
    fontSize: 14,
    fontWeight: '500',
    color: theme.palette.gray[700],
  },
  loginButton: {
    backgroundColor: theme.palette.primary[500],
    borderWidth: 0,
    height: 60,
    width: 100,
    marginLeft: theme.getSpacing(1),
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
  },
  selectedUser: {
    backgroundColor: theme.palette.primary[50],
    borderColor: theme.palette.primary[500],
  },
  authInput: {
    height: 50,
    padding: 5,
    backgroundColor: theme.palette.white,
  },
  forgotPasswordText: {
    color: theme.palette.primary[700],
    fontSize: 16,
  },
}));
