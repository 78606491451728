export const pharmacySoftwares = [
  {
    value: 'TeleManagerXMLv2',
    label: 'PMS Simulator',
    fields: ['server_port'],
  },
  {
    value: 'LibertyV2',
    label: 'Liberty',
    fields: [
      'api_key',
      'auth_user',
      'npi',
      'auth_secret',
      'is_subscribed_to_webhooks',
    ],
  },
];
