import { Text } from 'react-native';
import { getText } from 'assets/localization/localization';
import { Modal } from 'assets/components/modal';
import { Form } from 'assets/layout';
import { TextField } from 'assets/components/text-field';
import { Button } from 'assets/components/button';
import { FC, useState } from 'react';
import ShowMoreSection from './ShowMoreSection';
import { StyleSheet } from 'react-native';
import { UseFormReturn } from 'react-hook-form';
import { ArrowsDown, ArrowsUp } from 'assets/icons';
import { IPatientForm, ModalType } from './types';
import { notImplementedAlert } from 'assets/utils/alert';
import * as validate from '@digitalpharmacist/validation-dp';

interface IPatientFormModalProps {
  title: string;
  methods: UseFormReturn<IPatientForm>;
  show: boolean;
  error?: string;
  onSubmit: (data: IPatientForm) => void;
  setShowPatientFormModal: (prop: boolean) => void;
  resetDataInForm?: () => void;
  setPhoto: (prop: File | undefined) => void;
  disableShowMoreButton?: boolean;
  modalType?: ModalType;
}

const PatientFormModal: FC<IPatientFormModalProps> = ({
  title,
  methods,
  show,
  onSubmit,
  setShowPatientFormModal,
  resetDataInForm,
  setPhoto,
  disableShowMoreButton,
  modalType = ModalType.CREATE,
  error,
}) => {
  const [showMore, setShowMore] = useState(false);
  const onShowMorePress = () => {
    if (disableShowMoreButton) {
      notImplementedAlert();
    } else {
      setShowMore((prev) => !prev);
    }
  };

  function onDismiss(): void {
    setShowPatientFormModal(false);
    if (resetDataInForm) {
      resetDataInForm();
    }
  }

  return (
    <Modal
      title={title}
      cancelButtonProps={{
        onPress: onDismiss,
        logger: { id: 'confirmation-cancel-button' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(onSubmit),
        logger: { id: 'confirmation-accept-button' },
        hierarchy: 'pharmacy-primary',
      }}
      show={show}
      size="sm"
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Alert title={error} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('first-name')}
              name="first_name"
              rules={{
                required: getText('first-name'),
              }}
            />
          </Form.Column>
          <Form.Column>
            <TextField
              name="last_name"
              label={getText('last-name')}
              rules={{
                required: getText('last-name'),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              type="date"
              name="date_of_birth"
              label={getText('date-of-birth')}
              rules={{
                required: getText('date-of-birth'),
                validate: (value) => {
                  return validate.isDateOfBirth(value)
                    ? true
                    : getText('date-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={'phone'}
              type="telephoneNumber2"
              label={getText('phone')}
              rules={{
                required: getText('phone'),
                validate: {
                  value: (value) => {
                    return validate.isPhoneNumber(
                      // Backend is expecting 1 digit country code in front of the phone number
                      value ? '+1' + value : '',
                    )
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            hierarchy="primary"
            logger={{
              id: 'edit-patient-show-more-button',
            }}
            icon={showMore ? ArrowsUp : ArrowsDown}
            style={styles.showMore}
            onPress={onShowMorePress}
          >
            <Text style={styles.showMoreText}>
              {showMore ? getText('show-less') : getText('show-more')}
            </Text>
          </Button>
        </Form.Actions>
        {showMore ? (
          <ShowMoreSection
            setValue={methods.setValue}
            watch={methods.watch}
            setPhoto={setPhoto}
            modalType={modalType}
          />
        ) : null}
      </Form>
    </Modal>
  );
};

const styles = StyleSheet.create({
  title: {
    alignSelf: 'flex-start',
    fontWeight: '700',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 24,
    paddingBottom: 8,
  },
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    minHeight: '100%',
  },
  group: {
    marginTop: 20,
  },
  columnLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#E1EAEF',
  },
  subTitle: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    fontSize: 16,
  },
  row: {
    alignContent: 'stretch',
  },
  showMore: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    paddingTop: '4px',
    PaddingRight: '12px',
    paddingBottom: '4px',
    PaddingLeft: '12px',
    gap: 4,
    maxWidth: '135px',
    maxHeight: '28px',

    /* Gray/100 */
    backgroundColor: '#EAF1F4',
    borderRadius: 100,
    borderColor: '#EAF1F4',
  },
  showMoreText: {
    color: '#374D58',
  },
});

export default PatientFormModal;
