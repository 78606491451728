import {
  ClosureTimeRangeDto,
  NormalTimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';

export interface TimeRangeForm {
  startHour: string;
  endHour: string;
}

function pad2Number(min: number): string {
  return String(min).padStart(2, '0');
}

export function convertTimeRangeToISOString(
  timeRangeDto: NormalTimeRangeDto | ClosureTimeRangeDto,
): TimeRangeForm {
  const { start_hour, start_minute, end_hour, end_minute } = timeRangeDto;
  const startDate = new Date(0);
  startDate.setHours(start_hour);
  startDate.setMinutes(start_minute);
  const endDate = new Date(0);
  endDate.setHours(end_hour);
  endDate.setMinutes(end_minute);

  return { startHour: startDate.toISOString(), endHour: endDate.toISOString() };
}

export function extractHoursAndMinutesFromISOString(isoDateString: string) {
  const date = new Date(isoDateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return { hours, minutes };
}

export function convertTimeRangeToStringRange(
  timeRangeDto: NormalTimeRangeDto | ClosureTimeRangeDto,
): string {
  return `${timeRangeDto.start_hour % 12}:${pad2Number(
    timeRangeDto.start_minute,
  )} ${timeRangeDto.start_hour >= 12 ? 'AM' : 'PM'} - ${
    timeRangeDto.end_hour % 12
  }:${pad2Number(timeRangeDto.end_minute)} ${
    timeRangeDto.end_hour >= 12 ? 'AM' : 'PM'
  }`;
}

export function formatDate(date: Date): string {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}
