import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const ExternalLinkIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6m4-3h6m0 0v6m0-6L10 14"
      />
    </Svg>
  );
};
