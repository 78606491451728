import { logError, logEvent } from 'assets/logging/logger';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { useLoginState } from './login-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import { PharmacyUser } from '../../api';
import { ampli } from '../../common/ampliPharmacist';
import roleService from '../../api/RoleService';
import pharmacyService from '../../api/PharmacyService';
import UsersService from '../../api/UsersService';
import {
  PharmacistUserLoginRequest,
  PharmacyUserDto,
} from '@digitalpharmacist/users-service-client-axios';

// TODO: get pharmacy id from the login call
const HARDCODED_PHARMACY_ID = '09653b42-b122-4ebb-88bc-408eb59f7fd5';

// lastActivity is the recent time of the user logging in
const lastActivity = Math.floor(new Date().getTime() / 1000);

const sessionExp = Math.floor(new Date().getTime() / 1000) + 4 * 60 * 60;
export type UserInfoType = {
  userInfo: PharmacyUserDto;
  lastActivity: number;
  sessionExp: number;
};
export const login = async (
  values: PharmacistUserLoginRequest,
): Promise<void> => {
  useLoginState.setState({ error: undefined, status: 'loading' });

  try {
    const { accessToken, refreshToken, userInfo } =
      await UsersService.logIn(values);
    saveUserInfoToLocalStorage(userInfo!);
    // set pharmacy ID to state
    useAppStateStore.setState({
      pharmacyId: HARDCODED_PHARMACY_ID,
    });
    if (accessToken && refreshToken && userInfo) {
      // we should get all the information needed here
      await AsyncStorage.setItem(StorageKeys.AccessToken, accessToken);
      await AsyncStorage.setItem(StorageKeys.RefreshToken, refreshToken);
      useLoginState.setState({ status: 'success' });
      await AsyncStorage.setItem(StorageKeys.UserId, userInfo.id!);
      useUserState.setState({ data: userInfo });
      ampli.userLogin({
        pharmacy_id: HARDCODED_PHARMACY_ID,
        status: 'Logged In',
        method: 'lumistry',
      });
      const allUserDetails =
        await roleService.userRoleGetUsersDetailsByPharmacyId(
          HARDCODED_PHARMACY_ID,
        );

      const userDetails = allUserDetails.filter(
        (it) => it.userId === userInfo.id,
      );

      if (userDetails.length == 0) return;

      const pharmacyLocations = [];
      for (const location of userDetails[0].locations) {
        const pharmacyLocation =
          await pharmacyService.pharmacyLocationFindOne(location);
        pharmacyLocations.push(pharmacyLocation);
      }
      useAppStateStore.setState({ userLocations: pharmacyLocations });
      if (pharmacyLocations.length === 1) {
        useAppStateStore.setState({ locationId: pharmacyLocations[0].id });
      }
    }
  } catch (e) {
    logEvent('login_failed', { email: values.email });
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

const saveUserInfoToLocalStorage = async (user: PharmacyUserDto) => {
  try {
    if (!user) {
      throw Error('No user has logged in yet!');
    }
    const existingData = await AsyncStorage.getItem(StorageKeys.UserInfo);
    const data: Record<string, UserInfoType> = {};

    if (!user.id) {
      throw Error('No user ID!');
    }

    const userId = user.id;
    if (!data[userId]) {
      data[userId] = {
        userInfo: user,
        lastActivity,
        sessionExp,
      };
    } else {
      data[userId!] = {
        ...data[userId],
        lastActivity,
        sessionExp,
      };
    }
    if (existingData) {
      const parsedExistingData = JSON.parse(existingData);
      const userObject = { ...parsedExistingData, ...data };
      await AsyncStorage.setItem(
        StorageKeys.UserInfo,
        JSON.stringify(userObject),
      );
    } else {
      const userObject = data;
      await AsyncStorage.setItem(
        StorageKeys.UserInfo,
        JSON.stringify(userObject),
      );
    }
  } catch (err) {
    logEvent('login_failed', { email: user.email });
    useLoginState.setState({
      error: {
        message: err as string,
      },
      status: 'error',
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    AsyncStorage.multiRemove([
      '@UserId',
      '@RefreshToken',
      '@AccessToken',
      'AMP_unsent_a798881857',
      'EXPO_CONSTANTS_INSTALLATION_ID',
    ]);
    useUserState.setState({
      data: undefined,
    });
  } catch (error: any) {
    logError(error);
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: PharmacyUser;
}
