import React from 'react';
import { View, Text, Pressable } from 'react-native';
import {
  TrashIcon,
  EmptyCircleIcon,
  PlusCircleIcon,
  BookmarkIcon,
  EditIcon,
} from 'assets/icons';
import { Avatar } from 'assets/components/avatar';
import { makeStyles, useTheme } from 'assets/theme';
import useViewSidebarStore from './view-user-sidebar-store';
import { Icon } from 'assets/components/icon';
import { getText } from 'assets/localization/localization';
import { formatDate } from '../../../common/datetime-utils';
import { PharmacySidebar } from '../../../components/PharmacySidebar';
import { ColoredBadge } from '../../../components/ColoredBadge';
import { IconButton } from 'assets/components/icon-button';

const TextEntry = ({ label, value }: { label: string; value: string }) => {
  const styles = useStyles();
  return (
    <View>
      <Text style={styles.labelText}>{label}</Text>
      <Text style={styles.valueText}>{value}</Text>
    </View>
  );
};

const ActivityEntry = ({ label, value }: { label: string; value: string }) => {
  const styles = useStyles();
  return (
    <View style={styles.row}>
      <Text style={styles.activityEntryLabel}>{label}</Text>
      <Text style={styles.activityEntryValue}>{value}</Text>
    </View>
  );
};

export default function UserDetailSidebar() {
  const theme = useTheme();
  const styles = useStyles();
  const { userDetails, setShowConfirmDeleteModal } = useViewSidebarStore();

  if (!userDetails) {
    throw new Error('userDetails is undefined');
  }

  return (
    <PharmacySidebar title={getText('user-details')} dataExists={!!userDetails}>
      <View>
        <View style={styles.row}>
          <View style={styles.buttonsRow}>
            <IconButton
              onPress={() => setShowConfirmDeleteModal(true)}
              icon={TrashIcon}
              logger={{ id: 'delete-user' }}
              color={theme.palette.gray[500]}
              size={15}
            />
            <Pressable>
              <View style={styles.row}>
                <IconButton
                  onPress={() => {}}
                  icon={EditIcon}
                  logger={{ id: 'bookmark-user' }}
                  color={theme.palette.gray[500]}
                  size={15}
                />
                <Text style={styles.editText}>{getText('edit')}</Text>
              </View>
            </Pressable>
          </View>
        </View>
        <View style={[styles.row, styles.paddingTop3]}>
          <Text style={styles.userName}>{userDetails.full_name}</Text>
          <Avatar
            name={userDetails.full_name}
            size={40}
            color={theme.palette.gray[200]}
          />
        </View>
        {/* These properties never existed and do not function as intended. See https://rxwiki.atlassian.net/browse/BLUES-3180 */}
        {/* additional note, role here is a string which makes no sense roles in an array and users can have many. Not fixing atm */}
        {/* <TextEntry label={getText('title')} value={userDetails.title} /> */}
        <TextEntry label={getText('email-text')} value={userDetails.email} />
        <TextEntry
          label={getText('role')}
          value={userDetails.roles.join(',')}
        />

        <View style={[styles.row, styles.activityHeader]}>
          <Text style={styles.activityLabelText}>
            {getText('activity').toUpperCase()}
          </Text>
          <Pressable style={[styles.row, styles.justifyContentEnd]}>
            <Text style={styles.showButtonText}>{getText('show')}</Text>
            <Icon
              icon={PlusCircleIcon}
              color={theme.palette.primary[600]}
              size={16}
            />
          </Pressable>
        </View>

        {/* <ActivityEntry
          label={getText('last-active')}
          value={formatDate(userDetails.last_active)}
        />
        <ActivityEntry
          label={getText('registered')}
          value={formatDate(userDetails.registered_on)}
        /> */}
      </View>
    </PharmacySidebar>
  );
}

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 16,
    fontWeight: '600',
    color: theme.palette.gray[900],
    lineHeight: 18,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(1),
  },
  userName: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  valueText: {
    fontWeight: '400',
    color: theme.palette.gray[700],
  },
  activityHeader: {
    paddingTop: theme.getSpacing(3),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    borderBottomStyle: 'solid',
    marginBottom: theme.getSpacing(2),
  },
  activityLabelText: {
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.gray[900],
  },
  showButtonText: {
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
    fontSize: 13,
    fontWeight: '400',
  },
  activityEntryLabel: {
    fontWeight: '500',
    color: theme.palette.gray[900],
    lineHeight: 20,
    paddingBottom: theme.getSpacing(1),
  },
  activityEntryValue: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    lineHeight: 20,
  },
  editText: {
    color: theme.palette.gray[500],
    fontSize: 14,
  },
  paddingTop3: {
    paddingTop: theme.getSpacing(3),
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  badgeText: {
    fontSize: 12,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
}));
