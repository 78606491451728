import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PaginatedRemoteDatasource } from 'assets/components/data-grid/data-grid-toolkit/datasource/PaginatedRemoteDatasource';

export interface ISubmissionsPaginatedDatasourceServiceFactory {
  getSubmissionsPaginatedDatasource: (
    formsEndpointUrl: string,
    locationId: string,
  ) => void;
}

export class SubmissionsPaginatedDatasourceServiceFactory
  extends BaseApiService
  implements ISubmissionsPaginatedDatasourceServiceFactory
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  getSubmissionsPaginatedDatasource(
    formsEndpointUrl: string,
    locationId: string,
  ) {
    return new PaginatedRemoteDatasource(
      `${formsEndpointUrl}/locations/${locationId}/forms/submissions`,
      this.axiosInstance,
    );
  }
}
