import {
  AppointmentTypeSlotDto,
  BookingDto,
  CreateBookingDto,
  UpdateBookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { logError } from 'assets/logging/logger';
import { getText } from '../../../../../packages/assets/localization/localization';
import AppointmentService from '../../api/AppointmentService';
import PatientService from '../../api/PatientService';
import { useToast } from '../../common/hooks/useToast';
import { LocationPatientRecordDtoWithFullName } from '../../screens/patients/patient-types';
import { useAppStateStore } from '../../store/app-store';
import { useAppointmentFormState } from './appointment-form-store';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to set the appointment. Please try again.';

  logError(error);
  useAppointmentFormState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const getAppointmentSlots = async (
  appointmentTypeId: string,
  startDate: string,
  endDate: string,
) => {
  useAppointmentFormState.setState({ slotsStatus: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.getAvailableAppointmentSlots(
      pharmacyId,
      locationId,
      appointmentTypeId,
      startDate,
      endDate,
    );

    useAppointmentFormState.setState({
      slotsStatus: 'success',
      slots: response,
      slotsAppointmentTypeId: appointmentTypeId,
    });
  } catch (error: any) {
    errorOccurred(
      error,
      'An error occurred while trying to get appointment slots. Please try again',
    );
  }
};

export const createBooking = async (booking: CreateBookingDto) => {
  useAppointmentFormState.setState({ status: 'loading' });
  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();
    const { toast } = useToast();

    await AppointmentService.createBooking(pharmacyId, locationId, booking);

    useAppointmentFormState.setState({ status: 'success' });
    toast('Appointment booked', { type: 'success' });
  } catch (error) {
    errorOccurred(error);
  }
};

export const getBooking = async (bookingId: string) => {
  useAppointmentFormState.setState({ bookingStatus: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.getBooking(
      pharmacyId,
      locationId,
      bookingId,
    );

    useAppointmentFormState.setState({
      bookingStatus: 'success',
      booking: response,
    });
  } catch (error) {
    errorOccurred(
      error,
      'An error occurred while trying to load the appointment. Please try again.',
    );
  }
};

export const updateBooking = async (
  bookingId: string,
  booking: UpdateBookingDto,
) => {
  useAppointmentFormState.setState({ status: 'loading' });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();
    const { toast } = useToast();

    const response = await AppointmentService.updateBooking(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    useAppointmentFormState.setState({
      status: 'success',
      booking: response,
    });
    toast('Appointment updated', { type: 'success' });
  } catch (error) {
    errorOccurred(
      error,
      'An error occurred while trying to update the appointment. Please try again.',
    );
  }
};

export const setSubmit = (submit: boolean) => {
  useAppointmentFormState.setState({ submit });
};

export const submitAppointmentForm = () => {
  setSubmit(true);
};

export const setSlots = (slots: AppointmentTypeSlotDto[]) => {
  useAppointmentFormState.setState({ slots });
};

export const setLocationPatientRecord = (
  locationPatientRecord: LocationPatientRecordDtoWithFullName | undefined,
) => {
  useAppointmentFormState.setState({ locationPatientRecord });
};

export const getPatientRecord = async (patientRecordId: string) => {
  useAppointmentFormState.setState({ patientRecordStatus: 'loading' });

  try {
    const response = await PatientService.getPatientRecord(patientRecordId);

    useAppointmentFormState.setState({
      patientRecordStatus: 'success',
      patientRecord: response,
    });
  } catch (error) {
    useAppointmentFormState.setState({
      patientRecordStatus: 'error',
    });
    errorOccurred(error, getText('error-loading-patient'));
  }
};

export const setIsDisabled = (isDisabled: boolean) => {
  useAppointmentFormState.setState({ isDisabled });
};

export const setPatientRecord = (
  patientRecord: PatientRecordDto | undefined,
) => {
  useAppointmentFormState.setState({ patientRecord });
};

export const setBooking = (booking: BookingDto | undefined) => {
  useAppointmentFormState.setState({ booking });
};
