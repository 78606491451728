import { FunctionComponent } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { XIcon } from 'assets/icons';
import { ATTACHMENT_HEIGHT } from '../data';

export const Attachments: FunctionComponent<AttachmentsProps> = ({
  files = [],
  onRemoveFile,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.container}>
      {files.map((file) => (
        <View style={styles.fileWrapper} key={file.name.replaceAll(' ', '-')}>
          <Text style={{ marginRight: theme.getSpacing(1) }}>{file.name}</Text>
          <TouchableOpacity onPress={() => onRemoveFile(file.name)}>
            <XIcon size={ATTACHMENT_HEIGHT} />
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
  },
  fileWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: ATTACHMENT_HEIGHT,
    height: ATTACHMENT_HEIGHT,
  },
}));

interface AttachmentsProps {
  files: File[];
  onRemoveFile: (name: string) => void;
}
