import { DataGrid } from 'assets/components/data-grid';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getText } from 'assets/localization/localization';
import useViewSidebarStore from './view-sidebar-store';
import { Icon } from 'assets/components/icon';
import { Avatar } from 'assets/components/avatar';
import { AlertTriangleIcon } from 'assets/icons';
import RefillDetailSidebar from './RefillDetailSidebar';
import prescriptionService from '../../api/PrescriptionService';
import { useProSidebar } from 'react-pro-sidebar';
import { formatTimeDate } from '../../common/datetime-utils';
import { useAppStateStore } from '../../store/app-store';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import PatientDetailSidebar from '../patients/PatientDetailSidebar';
import usePatientStore from '../patients/patient-store';
import {
  LocationPatientRecordDtoWithFullName,
  SidebarDetails as SidebarPatientDetails,
} from '../patients/patient-types';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';

export default function RefillSubmissions() {
  const styles = useStyles();
  const theme = useTheme();
  const { locationId } = useAppStateStore();
  const { sidebarDetails, setSidebarDetails } = useViewSidebarStore();
  const {
    sidebarDetails: patientSidebarDetails,
    setSidebarDetails: setPatientSidebarDetails,
  } = usePatientStore();

  const [rowData, setRowData] = useState<RefillSubmittedDto[]>(); // start with an empty list
  const { collapseSidebar } = useProSidebar();
  const [columnDefs] = useState<Array<ColDef | ColGroupDef>>([
    {
      headerName: getText('patient'),
      cellRenderer: FullNameRenderer,
    },
    {
      field: 'rx_number',
      headerName: getText('rx-number'),
    },
    {
      field: 'ingress_method',
      headerName: getText('channel'),
      cellRenderer: ChannelFieldRenderer,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'fulfillment_method',
      headerName: getText('method'),
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'refill_status',
      headerName: getText('status'),
      cellRenderer: StatusFieldRenderer,
    },
    {
      field: 'submitted_at',
      headerName: getText('date-submitted'),
      headerClass: 'date-submitted-header',
      cellRenderer: DateFieldRenderer,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  ]);

  useEffect(() => {
    void loadRefillSubmitted();
  }, [locationId]);

  const loadRefillSubmitted = async () => {
    const findPrescriptionResponse =
      await prescriptionService.findPrescriptionRefills(locationId);

    setRowData(findPrescriptionResponse);
  };

  const fillRefillSidebarDetails = (
    selectedRefillSubmission: RefillSubmittedDto,
  ) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setPatientSidebarDetails(undefined as unknown as SidebarPatientDetails);

    void prescriptionService
      .fillPatientDetailsSidebar(locationId, selectedRefillSubmission)
      .then((response) => {
        setSidebarDetails(response);
      });
  };

  const fillPatientSideBarDetails = async () => {
    const locationPatientRecord = sidebarDetails?.patientDetails;

    if (!locationPatientRecord) return;

    const prescriptions = await prescriptionService.findPrescriptions(
      locationId,
      locationPatientRecord.id,
    );

    const locationPatientRecordWithFullName: LocationPatientRecordDtoWithFullName =
      {
        ...locationPatientRecord,
        full_name: `${locationPatientRecord.first_name} ${locationPatientRecord.last_name}`,
      };

    setPatientSidebarDetails({
      patientNumberInList: -1,
      locationPatientRecord: locationPatientRecordWithFullName,
      patientPrescriptions: prescriptions,
    });
  };

  const handlePatientDetailsPress = () => {
    void fillPatientSideBarDetails();
  };

  const handlePatientDetailsCollapse = () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setPatientSidebarDetails(undefined as unknown as SidebarPatientDetails);
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <DataGrid
          gridOptions={{
            rowData,
            columnDefs: columnDefs,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: true, menuTabs: [] },
            pagination: true,
            paginationPageSize: 10,
            loadingOverlayComponent: 'loadingIndicator',
            loadingOverlayComponentParams: {
              color: theme.colors.pharmacyPrimary,
            },
            components: {
              loadingIndicator: LoadingIndicator,
            },
            rowSelection: 'single',
            suppressCellFocus: true,
            colResizeDefault: 'shift',
            onRowClicked(event) {
              collapseSidebar(false);
              fillRefillSidebarDetails(event.node.data);
            },
          }}
          gridToolbarProps={{
            titleProps: {
              title: getText('refill-submissions'),
            },
            inputSearchProps: {
              size: 'lg',
              placeholder: getText('search'),
            },
          }}
        />
      </View>
      {sidebarDetails && !patientSidebarDetails && (
        <RefillDetailSidebar
          onPatientDetailsPress={handlePatientDetailsPress}
        />
      )}
      {sidebarDetails && patientSidebarDetails && (
        <PatientDetailSidebar onCollapse={handlePatientDetailsCollapse} />
      )}
    </View>
  );
}

export const FullNameRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { patient_first_name, patient_last_name } =
    params.data as RefillSubmittedDto;
  const fullName = `${patient_first_name} ${patient_last_name}`;

  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
      >
        <Avatar name={fullName} size={32} color={theme.palette.gray[200]} />
      </View>
      <div>{fullName} </div>
    </View>
  );
};

export const StatusFieldRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { refill_status } = params.data as RefillSubmittedDto;
  const statusText = refill_status?.replaceAll('_', ' ');

  if (refill_status === RefillStatus.Accepted) {
    return <div>{getText('success')}</div>;
  } else {
    return (
      <View style={styles.iconContainer}>
        <div
          style={{
            marginRight: theme.getSpacing(1),
            color: theme.colors.error,
            textTransform: 'capitalize',
          }}
        >
          {statusText}
        </div>
        <View
          style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
        >
          <Icon icon={AlertTriangleIcon} size={16} color={theme.colors.error} />
        </View>
      </View>
    );
  }
};

export const ChannelFieldRenderer = (params: ICellRendererParams) => {
  const channel = params.value;

  return <div>{channel === 'ivr' ? channel.toUpperCase() : channel}</div>;
};

export const DateFieldRenderer = (params: ICellRendererParams) => {
  return <div>{formatTimeDate(params.value)}</div>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    color: theme.palette.gray[700],
  },
}));
