import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';

export const ContactInfoItemRenderer: FunctionComponent<
  PropsWithChildren<ContactInfoItemRendererProps>
> = ({ email, phone, fax, shipping_address, billing_address }) => {
  return (
    <>
      <RowItemRender
        title={getText('pharmacy-email-address')}
        value={email ?? ''}
      />
      <RowItemRender
        title={getText('pharmacy-phone-number')}
        value={phone ?? ''}
      />
      <RowItemRender title={getText('pharmacy-fax-number')} value={fax ?? ''} />
      <RowItemRender title={getText('pharmacy-mailing-address')}>
        {shipping_address && <AddressViewComponent {...shipping_address} />}
      </RowItemRender>
      <RowItemRender title={getText('pharmacy-billing-address')}>
        {billing_address && <AddressViewComponent {...billing_address} />}
      </RowItemRender>
    </>
  );
};

const AddressViewComponent: FunctionComponent<AddressForm> = ({
  address1,
  address2,
  postal_code,
  city,
  state,
}) => {
  const styles = useStyles();
  return (
    <View>
      <Text style={styles.value}>{address1}</Text>
      <Text style={styles.value}>{address2}</Text>
      <Text style={styles.value}>
        {city}, {state.toString()} {postal_code}
      </Text>
    </View>
  );
};

interface OptionListModel {
  value: string;
  label: string;
}
interface AddressForm {
  address1: string;
  address2?: string;
  postal_code: string;
  city: string;
  state: OptionListModel;
  country: OptionListModel;
}

export interface ContactInfoItemRendererProps {
  email?: string;
  phone?: string;
  fax?: string;
  shipping_address?: AddressForm;
  billing_address?: AddressForm;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
