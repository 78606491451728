import React, { FunctionComponent } from 'react';
import { TooltipWrapper } from 'react-tooltip';
import { EllipsisTextRenderer } from '../components/EllipsisTextRenderer';

export const EllipsisTextRendererTooltip: FunctionComponent<
  EllipsisTextRendererTooltipProps
> = ({ value, rowIndex, setTooltipData }) => {
  const handleMouseEnter = () =>
    setTooltipData({ value, id: `${value} ${rowIndex}` });

  const handleMouseLeave = () => setTooltipData(undefined);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <TooltipWrapper tooltipId={`${value} ${rowIndex}`}>
        <EllipsisTextRenderer value={value} />
      </TooltipWrapper>
    </div>
  );
};
interface EllipsisTextRendererTooltipProps {
  value: string;
  rowIndex: number;
  setTooltipData: React.Dispatch<
    React.SetStateAction<
      | {
          value: string;
          id: string;
        }
      | undefined
    >
  >;
}
