import { AppointmentTypeSlotDto } from '@digitalpharmacist/appointment-service-client-axios';
import {
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_PHARMACY_TIMEZONE,
} from '../../common/datetime-utils';
import { AppointmentSlots } from '../appointment-slot-picker/AppointmentSlotPicker';

export const parseTimeSlots = (
  slots: AppointmentTypeSlotDto[],
): AppointmentSlots => {
  const appointmentSlots: AppointmentSlots = {};

  slots.map((slot) => {
    const date = convertDateTimeFromUtcToPharmacyTimezone(
      slot.time,
      DEFAULT_PHARMACY_TIMEZONE,
      'YYYY-MM-DD',
    );
    const existingSlot = appointmentSlots[date];

    if (existingSlot) {
      appointmentSlots[date].push(slot);
    } else {
      appointmentSlots[date] = [slot];
    }
  });

  return appointmentSlots;
};
