import create from 'zustand';
import { BaseFilterValues, InboxState } from '../types';
import { sortingOptions, TAKE, textFilters } from '../data';

export const useInboxState = create<InboxState>(() => ({
  patients: [],
  selectedPatient: null,
  selectedUserNames: {},
  selectedConversation: null,
  viewedConversations: [],
  conversationsSorting: sortingOptions[0],
  messagesGroups: [],
  selectedMessages: [],
  rawConversations: [],
  rawConversationsCount: 0,
  isOpenNewChatModal: false,
  templates: [],
  pharmacyHasPatientRecords: false,
  messagesPagination: {
    skip: 0,
    take: TAKE,
    count: 0,
  },
  showAddPatientModal: false,
  showEditModal: false,

  baseFilter: BaseFilterValues.ALL,
  multiFilters: [],
  textFilters: textFilters,
  disabled: false,
  count: {},
}));
