import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { FC } from 'react';
import { View, Text } from 'react-native';
import { Button } from 'assets/components/button';
import patientService from '../../../api/PatientService';
import { setSelectedPatient } from '../inbox-store/inbox-actions';
import { useInboxState } from '../inbox-store/inbox-store';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { AlertTriangleIcon } from 'assets/icons/AlertTriangleIcon';
import { Icon } from 'assets/components/icon';
import { AlertTestIDs } from 'assets/components/alert';

interface VerificationAlertProps {
  locationId: string;
}

const VerificationAlert: FC<VerificationAlertProps> = ({ locationId }) => {
  const theme = useTheme();
  const styles = useStyles();

  const { selectedPatient } = useInboxState();

  const colors = useColors();
  const color = colors['error'];

  const verifyPatient = async () => {
    if (selectedPatient) {
      const verifiedPatient: LocationPatientRecordDto =
        await patientService.updateLocationPatientRecord(
          locationId,
          selectedPatient.id,
          {
            is_verified: true,
          },
        );

      setSelectedPatient({
        ...selectedPatient,
        is_verified: verifiedPatient.is_verified,
      });
    }
  };

  if (selectedPatient?.is_verified) {
    return null;
  }

  return (
    <View
      style={[
        styles.root,
        {
          borderColor: color.border,
          backgroundColor: color.background,
          borderWidth: 1,
          borderRadius: theme.roundness,
          padding: theme.getSpacing(2),
        },
      ]}
    >
      <View>
        <Icon
          icon={AlertTriangleIcon}
          color={color.emphasis}
          size={16}
          testID={AlertTestIDs.icon}
        />
      </View>
      <View style={styles.textContainer}>
        <View>
          <Text
            style={{
              color: color.emphasis,
              fontSize: 14,
              fontWeight: '500',
            }}
            testID={AlertTestIDs.titleText}
          >
            {getText('verify-patient-warning')}
          </Text>
        </View>
        <Button
          hierarchy="tertiary"
          size="small"
          logger={{ id: 'verify-patient-button' }}
          labelStyle={{ color: theme.palette.primary[400], marginLeft: 0 }}
          style={styles.button}
          onPress={verifyPatient}
        >
          {getText('verify-patient')}...
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  textContainer: {
    flexDirection: 'column',
    marginLeft: theme.getSpacing(1),
    flexWrap: 'wrap',
    flex: 1,
    flexGrow: 1,
  },
  button: {
    display: 'flex',
    alignItems: 'flex-start',
    height: theme.getSpacing(4) + theme.getSpacing(0.5),
  },
}));

const useColors = () => {
  const theme = useTheme();
  return {
    error: {
      border: theme.palette.error[300],
      background: theme.palette.error[25],
      emphasis: theme.palette.error[700],
      description: theme.palette.error[600],
    },
  };
};

export default VerificationAlert;
