import { UserForm } from './UserTypes';
import UsersService from '../../../api/UsersService';
import RoleService from '../../../api/RoleService';
import { RoleOption, getRoleObjByRoleValue } from './UserHelpers';
import { EntityType } from '@digitalpharmacist/role-service-client-axios';
import PharmacyService from '../../../api/PharmacyService';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { RegisterPharmacistUserResponse } from '@digitalpharmacist/users-service-client-axios';

const upsertUser = async (
  firstName: string,
  lastName: string,
  email: string,
): Promise<RegisterPharmacistUserResponse> => {
  const user = await UsersService.userPharmacistRegisterPost({
    firstName,
    lastName,
    email,
  }).catch((e) => {
    if (e === 'Conflict') {
      // User exist, find and return;
      // TODO, if user already exist, return the user info. Waiting on BE endpoint https://rxwiki.atlassian.net/browse/BLUES-3355
      // for now.. throw
      console.debug('conflict user exists');
      throw e;
    } else {
      console.warn('error creating user: ', e);
      throw e;
    }
  });
  return user;
};

interface RoleToAdd {
  role: RoleOption;
  entityId: string;
  entityType: EntityType;
}

export const updateCreateUser = async (
  userForm: UserForm,
  pharmacyId: string,
) => {
  // validate form
  if (!userForm.role && !Object.keys(userForm?.roles || {}).length) {
    throw 'no roles';
  }

  // create or find user, if user exist, we will add roles

  const rolesToAddList: RoleToAdd[] = [];

  // if not per location, should apply to all
  if (!userForm?.roles?.length && !!userForm?.role) {
    const roleOption = getRoleObjByRoleValue(userForm?.role); // we need the entity type

    // We must populate a list since its applied to all locations in the pharmacy and the entity type is location
    if (roleOption?.entityType === EntityType.Location) {
      const result = await PharmacyService.pharmacyLocationFindAll();
      const locations: PharmacyLocationDto[] = result.locations;

      locations.forEach((location) => {
        rolesToAddList.push({
          role: roleOption,
          entityId: location.id,
          entityType: EntityType.Location,
        });
      });
    } else if (roleOption?.entityType === EntityType.Pharmacy) {
      // There is a role, not roles, and the entity type is pharmacy
      rolesToAddList.push({
        role: roleOption,
        entityId: pharmacyId,
        entityType: EntityType.Pharmacy,
      });
    }
  } else {
    // does not apply to all, user picked a set
    // this can only apply to roles with entity type as location
    for (const key in userForm.roles) {
      // key is the locationId
      const role = userForm.roles[key];
      const roleOption = getRoleObjByRoleValue(role);

      if (roleOption) {
        rolesToAddList.push({
          role: roleOption,
          entityId: key,
          entityType: EntityType.Location,
        });
      }
    }
  }

  // carry over....
  const { firstName, lastName, email } = userForm;
  if (!firstName || !lastName || !email) {
    throw 'form incomplete';
  }

  // If the user already exist, we still need to set the roles
  const user = await upsertUser(firstName, lastName, email);

  if (user.pharmacistId && rolesToAddList.length) {
    // This could be optimized with Promise.all, will leave as is for now.
    //for (const key in userForm.roles) {
    for (let roleToAdd of rolesToAddList) {
      await RoleService.userPharmacyRoleCreate(
        roleToAdd.role,
        user.pharmacistId,
        roleToAdd.entityId,
        pharmacyId,
      ).catch((e) => {
        console.warn('error creating pharmacy role:', e);
      });
    }
  }
};
