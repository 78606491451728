import { AxiosRequestConfig } from 'axios';
import { useAppStateStore } from '../store/app-store';

export const AXIOS_DEFAULT_REQUEST_CONFIG: AxiosRequestConfig = {
  headers: {
    get 'x-pharmacy-id'() {
      return (
        // TODO: remove once the pharmacyId issue will be resolved
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        useAppStateStore?.getState()?.pharmacyId ??
        '09653b42-b122-4ebb-88bc-408eb59f7fd5'
      );
    },
  },
};
