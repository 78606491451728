import create from 'zustand';
import { PharmacyUserDto } from '@digitalpharmacist/users-service-client-axios';

export interface UserState {
  data?: PharmacyUserDto;
  updateData: (data: UserState) => void;
  logout: () => void;
}

export const useUserState = create<UserState>((set) => ({
  data: undefined,
  updateData: (data: UserState) => {
    set({ ...data });
  },
  logout: () => {
    set({ data: undefined });
  },
}));

export const isUserAuthorized = (user: PharmacyUserDto | undefined) => {
  return (
    user?.id &&
    user.email &&
    user.privacyPolicyPromptAcceptanceTimestamp &&
    user.termsOfServicePromptAcceptanceTimestamp
  );
};
