import {
  AppointmentTypeSlotDto,
  BookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import create from 'zustand';
import { LocationPatientRecordDtoWithFullName } from '../../screens/patients/patient-types';
import { ApiError, AsyncStatus } from '../../store/types';

export const useAppointmentFormState = create<AppointmentFormState>(() => ({
  slotsStatus: 'idle',
  status: 'idle',
  bookingStatus: 'idle',
  patientRecordStatus: 'idle',
  slots: [],
  submit: false,
  isDisabled: true,
}));

interface AppointmentFormState {
  slotsStatus: AsyncStatus;
  status: AsyncStatus;
  bookingStatus: AsyncStatus;
  patientRecordStatus: AsyncStatus;
  slots: AppointmentTypeSlotDto[];
  submit: boolean;
  isDisabled: boolean;
  error?: ApiError;
  booking?: BookingDto;
  locationPatientRecord?: LocationPatientRecordDtoWithFullName;
  patientRecord?: PatientRecordDto;
  slotsAppointmentTypeId?: string;
}
