import { Role, EntityType } from '@digitalpharmacist/role-service-client-axios';

/**
 * Returns a role object RoleOption
 * when given the role value / (name)
 * @param roleValue
 * @returns
 */
export const getRoleObjByRoleValue = (
  roleValue: Role | string | undefined,
): RoleOption | undefined => {
  return allRoles.find(
    (roleOption: RoleOption) => roleOption.value === roleValue,
  );
};

export interface RoleOption {
  label: string;
  value: Role;
  manageableByRoles: Array<Role>; // This is a list of roles that can manage this role
  entityType?: EntityType;
}

export const roleOptions = {
  // returns a list of roles that are manageable by a given role and optional entity type
  // ignores entity type if not passed
  getAssignableRoleSet(
    usersRole: Role,
    entityType?: EntityType,
  ): Array<RoleOption> {
    return allRoles.filter((roleOption) => {
      const manageable = roleOption.manageableByRoles.includes(usersRole);
      const correctType = !entityType || entityType === roleOption.entityType;
      return manageable && correctType;
    });
  },
};

export const allRoles: Array<RoleOption> = [
  {
    label: 'Lumistry User',
    value: Role.LumistryUser,
    manageableByRoles: [Role.LumistryUser],
    entityType: undefined, // The lumistry user has no entity
  },
  {
    label: 'IT Admin',
    value: Role.ItAdmin,
    manageableByRoles: [Role.LumistryUser, Role.ItAdmin],
    entityType: EntityType.Pharmacy,
  },
  {
    label: 'Corporate',
    value: Role.Corporate,
    manageableByRoles: [Role.LumistryUser, Role.Corporate, Role.ItAdmin], // ? internal user
    entityType: EntityType.Pharmacy,
  },
  {
    label: 'Brand',
    value: Role.Brand,
    manageableByRoles: [
      Role.LumistryUser,
      Role.ItAdmin,
      Role.Brand,
      Role.Corporate,
    ],
    entityType: EntityType.Pharmacy,
  }, // Todo, update to Role.brand once available
  {
    label: 'Manager',
    value: Role.Manager,
    manageableByRoles: [
      Role.LumistryUser,
      Role.Brand,
      Role.Manager,
      Role.ItAdmin,
      Role.Corporate,
    ],
    entityType: EntityType.Location,
  },
  {
    label: 'Staff',
    value: Role.Staff,
    manageableByRoles: [
      Role.LumistryUser,
      Role.Brand,
      Role.Corporate,
      Role.ItAdmin,
      Role.Manager,
    ],
    entityType: EntityType.Location,
  },
  {
    label: 'No Access',
    value: Role.NoAccess,
    manageableByRoles: [
      Role.LumistryUser,
      Role.Brand,
      Role.Corporate,
      Role.ItAdmin,
      Role.Manager,
    ],
    entityType: EntityType.Location,
  },
];
