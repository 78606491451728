import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { PencilIcon, TrashWithStripesIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { convertTimeRangeToStringRange } from '../../../../components/Settings/utils/TimeRange';
import {
  getDepartments,
  getDepartmentsHours,
  getEditingDepartmentHours,
  removeDepartment,
  setEditingDepartmentId,
  setShowModalDepartmentHours,
} from '../pharmacy-settings-actions';
import { DaysOfWeekEnum, DepartmentHours } from '../pharmacy-settings-store';

export const DepartmentHoursItemRenderer: FunctionComponent<
  PropsWithChildren<DepartmentHours>
> = ({ departmentId, departmentName, departmentHours }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const styles = useStyles();
  const theme = useTheme();

  const handleDelete = (id: string) => {
    removeDepartment(id)
      .then(() => getDepartments())
      .then(() => getDepartmentsHours())
      .then(() => setShowCloseConfirmation(false))
      .catch((error: Error) => {
        logError(error);
      });
  };

  return (
    <>
      <View style={styles.viewStyle}>
        <View style={styles.textView}>
          <Text style={styles.textStyle}>{departmentName}</Text>
        </View>
        <View style={styles.weekViewStyle}>
          {Array.from({ length: 7 }).map((_, index) => {
            const dayData = departmentHours.filter(
              (item) => item.day === index + 1,
            );
            const dayOfWeek = DaysOfWeekEnum[index + 1];

            if (dayData.length === 0) {
              return (
                <View style={styles.dayViewStyle}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.dayTextStyle}>{dayOfWeek}</Text>
                  </View>
                  <View style={{ flex: 3 }}>
                    <Text style={styles.dayTextStyle}>{getText('closed')}</Text>
                  </View>
                </View>
              );
            }

            return (
              <View style={styles.dayViewStyle}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.dayTextStyle}>{dayOfWeek}</Text>
                </View>
                {dayData.map((timeRange) => {
                  return (
                    <View style={{ flex: 3 }}>
                      <Text style={styles.dayTextStyle}>
                        {convertTimeRangeToStringRange(timeRange)}
                      </Text>
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
        <View style={styles.buttonView}>
          <IconButton
            icon={PencilIcon}
            onPress={() => {
              setEditingDepartmentId(departmentId);
              getEditingDepartmentHours();
              setShowModalDepartmentHours(true);
            }}
            size={16}
            color={theme.palette.gray[700]}
            logger={{ id: 'task-type-renderer-action-button-edit' }}
          />
          <IconButton
            icon={TrashWithStripesIcon}
            onPress={() => {
              setEditingDepartmentId(departmentId);
              setShowCloseConfirmation(true);
            }}
            size={16}
            color={theme.palette.gray[700]}
            logger={{ id: 'task-type-renderer-action-button-delete' }}
          />
        </View>
        <Modal
          title={getText('pharmacy-delete-department')}
          cancelButtonProps={{
            onPress: () => {
              setShowCloseConfirmation(false);
            },
            logger: { id: 'confirmation-cancel-button' },
          }}
          okButtonProps={{
            onPress: () => handleDelete(departmentId),
            logger: { id: 'confirmation-accept-button' },
            hierarchy: 'pharmacy-primary',
          }}
          show={showCloseConfirmation}
          size="sm"
        ></Modal>
      </View>
    </>
  );
};

interface OptionListModel {
  value: string;
  label: string;
}
interface AddressForm {
  address1: string;
  address2?: string;
  postal_code: string;
  city: string;
  state: OptionListModel;
  country: OptionListModel;
}

export interface DepartmentHoursItemRendererProps {
  id: string;
  name: string;
  email?: string;
  phone?: string;
  fax?: string;
  shipping_address?: AddressForm;
  billing_address?: AddressForm;
}

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  textView: {
    flex: 1,
    flexDirection: 'row',
  },
  buttonView: {
    width: 60,
    flexDirection: 'row',
  },
  textStyle: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  weekViewStyle: {
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 4,
  },
  dayTextStyle: {
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  dayViewStyle: {
    flexDirection: 'row',
    width: '100%',
  },
}));
