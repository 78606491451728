import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { LoadingIndicator } from '../../../../packages/assets/components/loading-indicator';
import { makeStyles } from '../../../../packages/assets/theme';
import { RouteGuard, RouteGuardProps } from './RouteGuard';

// This is a wrapper component for the RouteGuard component.
// It shows a loading indicator instead of rendering the RouteGuard if we're waiting for some data.
export const LoadingRouteGuard: FunctionComponent<LoadingRouteGuardProps> = ({
  loading,
  guards,
  component,
  noAccessComponent,
}) => {
  const styles = useStyles();
  return (
    <>
      {loading ? (
        <View style={styles.loadingContainer}>
          <LoadingIndicator />
        </View>
      ) : (
        <RouteGuard
          guards={guards}
          component={component}
          noAccessComponent={noAccessComponent}
        />
      )}
    </>
  );
};

export interface LoadingRouteGuardProps extends RouteGuardProps {
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    paddingVertical: theme.getSpacing(4),
  },
}));
