import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PATIENT_API_BASE_URL } from '../common/constants';
import {
  DefaultApi as PatientServiceClient,
  PatientRecordDto,
  UpdatePatientRecordDto,
  UpdateLocationPatientRecordDto,
  LocationPatientRecordDto,
  CreatePatientRecordDto,
  CreateLocationPatientRecordDto,
  LocationPatientRecordSearchDto,
  DeleteCardDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPatientService {
  checkService(): any;
  findAllLocationsRecordsById(
    locationId: string,
  ): Promise<LocationPatientRecordDto[]>;
  getPatientRecord(id: string): Promise<PatientRecordDto>;
  updateLocationPatientRecord(
    locationId: string,
    id: string,
    updateLocationPatientRecordDto: UpdateLocationPatientRecordDto,
    options?: AxiosRequestConfig<any> | undefined,
  ): Promise<LocationPatientRecordDto>;
  updatePatientRecord(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto,
  ): Promise<PatientRecordDto>;
  getPatientLocationRecord(
    locationId: string,
    locationPatientRecordId: string,
  ): Promise<LocationPatientRecordDto>;
  patientRecordDeleteCard(
    patientRecordId: string,
    deleteCardDto: DeleteCardDto,
  ): Promise<PatientRecordDto>;
}

export class PatientService extends BaseApiService implements IPatientService {
  private patientServiceClient: PatientServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.patientServiceClient = new PatientServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  addLocationPatientRecord(
    id: string,
    createLocationPatientRecordDto: CreateLocationPatientRecordDto,
  ): Promise<LocationPatientRecordDto> {
    throw new Error('Method not implemented.');
  }

  async checkService(): Promise<any> {
    const response = await this.patientServiceClient.appGetHealthCheck();
    return response.data;
  }

  async findAllLocationsRecordsById(
    locationId: string,
  ): Promise<LocationPatientRecordDto[]> {
    const response =
      await this.patientServiceClient.locationPatientRecordFind(locationId);

    return response.data;
  }

  async initLocationPatientRecord(
    locationId: string,
    patientRecordId: string,
  ): Promise<PatientRecordDto> {
    const response =
      await this.patientServiceClient.patientRecordInitLocationPatientRecord(
        patientRecordId,
        { location_id: locationId },
      );

    return response.data;
  }
  async updateLocationPatientRecord(
    locationId: string,
    id: string,
    updateLocationPatientRecordDto: UpdateLocationPatientRecordDto,
    options?: AxiosRequestConfig<any> | undefined,
  ): Promise<LocationPatientRecordDto> {
    const response =
      await this.patientServiceClient.locationPatientRecordUpdate(
        locationId,
        id,
        updateLocationPatientRecordDto,
        options,
      );

    return response.data;
  }

  async getPatientRecord(id: string): Promise<PatientRecordDto> {
    const response = await this.patientServiceClient.patientRecordFindOne(id);

    return response.data;
  }

  async addPatientRecord(
    patientRecordDto: CreatePatientRecordDto,
  ): Promise<PatientRecordDto> {
    const response =
      await this.patientServiceClient.patientRecordCreate(patientRecordDto);

    return response.data;
  }

  async updatePatientRecord(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto,
  ): Promise<PatientRecordDto> {
    const response = await this.patientServiceClient.patientRecordUpdate(
      id,
      updatePatientRecordDto,
    );

    return response.data;
  }

  async getPatientLocationRecord(
    locationId: string,
    locationPatientRecordId: string,
  ): Promise<LocationPatientRecordDto> {
    const { data } =
      await this.patientServiceClient.locationPatientRecordFindOne(
        locationId,
        locationPatientRecordId,
      );

    return data;
  }

  async getPatientSearch(
    locationId: string,
    query: string,
    limit: number,
  ): Promise<LocationPatientRecordSearchDto[]> {
    const { data } =
      await this.patientServiceClient.locationPatientRecordSearch(
        locationId,
        query,
        limit,
      );

    return data;
  }

  async patientRecordDeleteCard(
    patientRecordId: string,
    deleteCardDto: DeleteCardDto,
  ): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordDeleteCard(
      patientRecordId,
      deleteCardDto,
    );
    return data;
  }
}

export default new PatientService(
  PATIENT_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
);
