import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';
import { PmsConfigDto } from '@digitalpharmacist/pms-integration-service-client-axios';

export const usePMSIntegrationsState = create<PMSIntegrationState>(() => ({
  pmsConfigurationExists: false,
  showPMSFormModal: false,
  pmsConfig: {
    id: '',
    subscribe_to_webhooks: false,
    integration_type: '',
    server_address: '',
    server_port: null,
    timeout: null,
    allowed_concurrency: null,
    auth_url: null,
    auth_user: null,
    auth_secret: null,
    multistore_enable: null,
    multistore_offset: null,
    multistore_length: null,
    npi: null,
    pharmacy_phone: null,
    token: null,
    api_host: null,
    subscription_key: null,
    api_key: null,
    is_subscribed_to_webhooks: true,
  },
  status: 'idle',
}));

interface PMSIntegrationState {
  pmsConfigurationExists: boolean;
  showPMSFormModal: boolean;
  pmsConfig: PmsConfigDto;
  status: AsyncStatus;
  error?: ApiError;
}
