import { getText } from 'assets/localization/localization';
import * as validate from '@digitalpharmacist/validation-dp';

export enum ModalType {
  'EDIT' = 'edit',
  'CREATE' = 'create',
}

export interface IRulesFields {
  street1: object;
  street2?: object;
  postal_code: object;
  email: object;
}

export const rules: Record<ModalType, IRulesFields> = {
  [ModalType.CREATE]: {
    street1: {
      validate: (value: any) => {
        return value === undefined || validate.isAddress(value)
          ? true
          : getText('street-1-is-not-valid');
      },
    },
    street2: {
      validate: (value: any) => {
        return value === undefined || validate.isAddress(value)
          ? true
          : getText('street-2-is-not-valid');
      },
    },
    postal_code: {
      validate: (value: any) => {
        return value === undefined || validate.isPostalCode(value, 'any')
          ? true
          : getText('zip-code-is-not-valid');
      },
    },
    email: {
      validate: (value: any) => {
        return value === null ||
          value === undefined ||
          value === '' ||
          validate.isEmail(value)
          ? true
          : getText('email-is-not-valid');
      },
    },
  },
  [ModalType.EDIT]: {
    street1: {
      validate: (value: any) => {
        return value === null ||
          value === undefined ||
          value === '' ||
          validate.isAddress(value)
          ? true
          : getText('street-1-is-not-valid');
      },
    },
    street2: {
      validate: (value: any) => {
        return value === null ||
          value === undefined ||
          value === '' ||
          validate.isAddress(value)
          ? true
          : getText('street-2-is-not-valid');
      },
    },
    postal_code: {
      validate: (value: any) => {
        return value === null ||
          value === undefined ||
          value === '' ||
          validate.isPostalCode(value, 'any')
          ? true
          : getText('zip-code-is-not-valid');
      },
    },
    email: {
      validate: (value: any) => {
        return value === null ||
          value === undefined ||
          value === '' ||
          validate.isEmail(value)
          ? true
          : getText('email-is-not-valid');
      },
    },
  },
};
