export interface Option {
  label: string;
  value: string;
}
export interface IPatientForm {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  phone: string;

  street1?: string;
  street2?: string;
  city?: string;
  postal_code?: string;
  state?: Option;
  country?: string;
  gender?: string;
  preferred_language: Option;
  email?: string;
  no_allergies?: boolean;
  allergies?: string;
  conditions?: string;
  prefers_easy_open_bottle_caps?: boolean;
  prefers_med_sync?: boolean;

  notify_by_voice?: boolean;
  notify_by_email?: boolean;
  notify_by_sms?: boolean;

  photo_id_url?: string;
}

export enum ModalType {
  'EDIT' = 'edit',
  'CREATE' = 'create',
}
