import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { ComboInput, ComboInputMode } from '../combo-input/ComboInput';
import { TimeRangeInput } from '../time-range-input/TimeRangeInput';
import { getText } from 'assets/localization/localization';

export const DailyAvailabilityInput: FunctionComponent<
  PropsWithChildren<DailyAvailabilityInputProps>
> = ({ label, name, id }) => {
  const styles = useStyles();

  return (
    <ComboInput
      conditionInput={(props) => (
        <View style={{ width: 200 }}>
          <CheckboxField
            {...props}
            label={label}
            mode={CheckboxInputMode.FLAT}
            id={`${id} checkbox-input`}
          />
        </View>
      )}
      input={(props) => (
        <TimeRangeInput
          {...props}
          startTimeRules={
            !props?.disabled
              ? { required: getText('start-time-required') }
              : undefined
          }
          endTimeRules={
            !props?.disabled
              ? { required: getText('end-time-required') }
              : undefined
          }
          containerStyle={styles.alignBaseline}
          id={`${id} time-range-input`}
        />
      )}
      name={name}
      mode={ComboInputMode.Disable}
    />
  );
};

export interface DailyAvailabilityInputProps {
  label: string;
  name: string;
  id: string;
}

const useStyles = makeStyles(() => ({
  alignBaseline: {
    alignItems: 'baseline',
  },
}));
