import React, { useEffect, useState } from 'react';
import { Modal } from 'assets/components/modal';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { useUserState } from '../../store/user-store';
import RadioButton from 'assets/components/radio-button-group/RadioButton';
import { useAppStateStore } from '../../store/app-store';
import { Text } from 'assets/components/text';
import {
  CheckIcon,
  ChevronDownIcon,
  GlobeIcon,
  SearchIcon,
} from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { BaseOptionType, MenuWrapper } from '../pharmacy-header/MenuWrapper';

export const PharmacyStoreSelector = () => {
  const styles = useStyles();

  const { data } = useUserState();
  const { userLocations, getUserLocations } = useAppStateStore();

  useEffect(() => {
    void (async () => {
      if (!data?.id) return;
      if (userLocations.length === 0) {
        await getUserLocations(data.id);
      }
    })();
  }, [data?.id]);

  return userLocations.length > 0 ? (
    <View style={styles.root}>
      {userLocations.length === 1 ? <SingleStore /> : <MultiStore />}
    </View>
  ) : (
    <></>
  );
};

const SingleStore = () => {
  const styles = useStyles();

  const { locationId, userLocations } = useAppStateStore();
  const currentLocation = userLocations.find((x) => x.id === locationId);

  return (
    <View style={styles.singleStore}>
      <Text style={styles.singleStoreText}>{currentLocation?.name}</Text>
    </View>
  );
};

const MultiStore = () => {
  const theme = useTheme();
  const styles = useStyles();

  const { locationId, userLocations } = useAppStateStore();

  // #region Temporary solution for testing, refactor this block
  const hqPharmacyTest = {
    isHQ: true,
    name: 'Pharmacy HQ Town',
    id: 'pharmacy-hq-id-test',
  };
  const selectedLocation =
    userLocations.find((x) => x.id === locationId) ??
    (locationId === hqPharmacyTest.id ? hqPharmacyTest : undefined);

  const findLocationOption =
    userLocations.length > 5
      ? [
          {
            isFindLocation: true,
            isHQ: false,
            name: 'Find a location ...',
          },
          {
            divider: true,
            isFindLocation: false,
            isHQ: false,
            name: 'divider',
          },
        ]
      : [];

  const items: Partial<
    PharmacyLocationDto & {
      isHQ: boolean;
      isFindLocation: boolean;
    } & BaseOptionType
  >[] = [
    ...findLocationOption,
    { ...hqPharmacyTest }, // TODO: remove this part once it is tested, just for testing purposes
    ...userLocations,
  ];
  // #endregion

  const [showModal, setShowModal] = useState(false);

  const handleDismissModal = () => {
    setShowModal(false);
  };

  const handleOnSave = () => {
    handleDismissModal();
  };

  const handleOnMenuPress = (item: any) => {
    if (item.isFindLocation) setShowModal(true);
    else handleOnChangeLocation(item);
  };

  // TODO: this should be a centralized change location action
  const handleOnChangeLocation = (value: PharmacyLocationDto) => {
    useAppStateStore.setState({ locationId: value.id });
  };

  return (
    <View style={styles.root}>
      <MenuWrapper
        anchor={
          <View style={styles.anchorContainer}>
            {(selectedLocation as any)?.isHQ && ( // TODO: refactor this line once we have the new definition from PharmacyLocationDTO
              <View style={styles.iconContainer}>
                <GlobeIcon size={20} color={theme.palette.white} />
              </View>
            )}

            <Text
              style={styles.anchorText}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {selectedLocation?.name}
            </Text>

            <View style={styles.iconContainer}>
              <ChevronDownIcon size={20} color={theme.palette.white} />
            </View>
          </View>
        }
        onPress={handleOnMenuPress}
        items={items}
        optionTemplate={(item) => (
          <View style={styles.optionContainer}>
            {item.isFindLocation && (
              <View style={{ marginRight: theme.getSpacing(1) }}>
                <Icon
                  icon={SearchIcon}
                  color={theme.palette.gray[500]}
                  size={16}
                />
              </View>
            )}
            {item.isHQ && (
              <View style={{ marginRight: theme.getSpacing(1) }}>
                <Icon
                  icon={GlobeIcon}
                  color={theme.palette.gray[500]}
                  size={16}
                />
              </View>
            )}
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={{
                ...styles.optionText,
                color:
                  item.id == locationId
                    ? theme.palette.primary[600]
                    : theme.palette.gray[900],
              }}
            >
              {item.name}
            </Text>
            {item.id == locationId && (
              <View style={{ marginLeft: theme.getSpacing(1) }}>
                <Icon
                  icon={CheckIcon}
                  color={theme.palette.primary[600]}
                  size={16}
                />
              </View>
            )}
          </View>
        )}
        menuStyle={{ width: 250, marginTop: 45 }}
        menuItemStyle={{ height: 32 }}
      />

      <Modal
        size="sm"
        title="Find your store"
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          logger: { id: 'cancel-button-modal-store-selector' },
        }}
        okButtonProps={{
          onPress: handleOnSave,
          logger: { id: 'ok-button-modal-store-selector' },
        }}
        isScrollable
      >
        {userLocations.map((item) => (
          <View style={styles.radioButtonContainer} key={item.id}>
            <RadioButton
              selected={locationId === item.id}
              onValueChange={() => handleOnChangeLocation(item)}
              text={item.name}
              value={item.id}
            />
          </View>
        ))}
      </Modal>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'center',
  },
  singleStore: { padding: theme.getSpacing(1) },
  singleStoreText: {
    color: theme.palette.white,
    opacity: 0.8,
    fontSize: 16,
    lineHeight: 24,
  },
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  dropdownElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  anchorContainer: {
    flexDirection: 'row',
    backgroundColor: theme.palette.primary[800],
    borderRadius: theme.roundness,
    paddingLeft: theme.getSpacing(3),
    paddingRight: theme.getSpacing(3),
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    gap: theme.getSpacing(2),
    width: 250,
    height: 44,
  },
  anchorText: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    flex: 1,
  },
  iconContainer: {
    justifyContent: 'center',
    alignContent: 'center',
  },
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  optionText: {
    flex: 1,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
}));
