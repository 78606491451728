import { IconButton } from 'assets/components/icon-button';
import { DownloadIcon, ImageIcon, TrashIcon } from 'assets/icons';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import { makeStyles, useTheme } from 'assets/theme';
import {
  View,
  Text,
  useWindowDimensions,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import fileStorageService from '../../api/FileStorageService';
import * as documentPicker from 'expo-document-picker';
import { getText } from 'assets/localization/localization';
import { FC, useEffect, useState } from 'react';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import usePatientStore from '../../screens/patients/patient-store';
import { ModalType } from './types';

interface IPhotoIdProps {
  setPhoto: (prop: File | undefined) => void;
  modalType: ModalType;
}

const PhotoId: FC<IPhotoIdProps> = ({ setPhoto, modalType }) => {
  const theme = useTheme();
  const styles = useStyles();

  const imageExtensions: string[] = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/jfif',
    'image/pjpeg',
    'image/pjp',
    'image/png',
    'image/svg',
    'image/webp',
  ];
  const { patientRecord } = usePatientStore();

  const [uri, setUri] = useState('');

  const { locationId, pharmacyId } = useAppStateStore();

  useEffect(() => {
    const getUri = async () => {
      if (patientRecord?.photo_id_url) {
        const responseReadUrl = await fileStorageService.readUrl(
          LocationCategory.Patients,
          locationId,
          patientRecord.photo_id_url,
          pharmacyId,
        );

        setUri(responseReadUrl.data.url);
      }
    };
    if (modalType === ModalType.EDIT && patientRecord) {
      getUri();
    }
  }, [patientRecord]);

  const onDeletePress = async () => {
    setUri('');
    setPhoto(undefined);
  };

  const choosePhoto = async () => {
    const docsResult: documentPicker.DocumentResult =
      await documentPicker.getDocumentAsync({
        type: imageExtensions,
      });

    if (docsResult.type === 'cancel' || !docsResult.output) {
      return;
    }

    const [file] = Object.values(docsResult.output);

    setPhoto(file);

    setUri(docsResult.uri);
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{getText('photo-id-2')}</Text>
        <View style={styles.buttons}>
          <IconButton
            onPress={choosePhoto}
            icon={DownloadIcon}
            logger={{ id: 'download-photo-id' }}
            color={theme.palette.gray[500]}
            size={17}
          />
          <IconButton
            onPress={() => void onDeletePress()}
            icon={TrashIcon}
            logger={{ id: 'download-photo-id' }}
            color={theme.palette.gray[500]}
            size={17}
          />
        </View>
      </View>
      {uri ? (
        <ImageBackground
          source={{ uri }}
          resizeMode="contain"
          style={styles.image}
        />
      ) : (
        <TouchableOpacity onPress={choosePhoto}>
          <View style={styles.emptyStateCard}>
            <View style={styles.emptyStateInsideCard}>
              <View>
                <ImageIcon size={32} color={theme.palette.gray[500]} />
              </View>
              <View style={styles.upload}>
                <FileUploadIcon color={theme.palette.gray[500]} size={24} />
                <Text style={styles.uploadText}>{getText('upload')}</Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 0.5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: { fontSize: 16 },
  buttons: {
    flexDirection: 'row',
  },
  image: {
    height: useWindowDimensions().height * 0.2,
  },
  emptyStateCard: {
    height: useWindowDimensions().height * 0.2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.gray[100],
    borderRadius: 12,
  },
  emptyStateInsideCard: {
    padding: theme.getSpacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  upload: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
}));

export default PhotoId;
