import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { TextProps } from 'react-native';
import { Text as PaperText } from 'react-native-paper';
import { makeStyles, useTheme } from '../../theme';
import { Icon } from 'assets/components/icon';
import { EyeIcon, EyeOffIcon } from 'assets/icons';
import { View, TouchableOpacity } from 'react-native';
import { getText } from '../../localization/localization';

export const HiddenText: FunctionComponent<PropsWithChildren<TextProps>> = (
  props,
) => {
  const [isHidden, setIsHidden] = useState(true);
  const theme = useTheme();
  const styles = useStyles();
  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <View style={styles.secretValue}>
      {isHidden ? (
        <PaperText>**********</PaperText>
      ) : (
        <PaperText {...props}>{props.children}</PaperText>
      )}
      <TouchableOpacity style={styles.hideAndShow} onPress={toggleHidden}>
        <Icon
          icon={isHidden ? EyeIcon : EyeOffIcon}
          color={theme.palette.gray[400]}
          size={16}
        />
        <PaperText style={styles.text}>
          {isHidden ? getText('show') : getText('hide')}
        </PaperText>
      </TouchableOpacity>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  secretValue: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    ...theme.fonts.regular,
    fontSize: 14,
    color: theme.palette.gray[700],
  },
  hideAndShow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    marginLeft: 8,
  },
}));
