import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { Button } from '../../../../../packages/assets/components/button';
import { useNavigation } from '@react-navigation/native';
import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { LoadingOverlay } from '../../components/LoadingOverlay';

export const SelectLocation: FunctionComponent = () => {
  const theme = useTheme();
  const { data } = useUserState();
  const { userLocations } = useAppStateStore();
  const navigation = useNavigation<RootStackNavigationProp>();
  const styles = useStyles();

  const [stores, setStores] = useState<
    Array<{ id: string; name: string; address: string }>
  >([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!data) return;
    for (const location of userLocations) {
      setStores((current) => [
        ...current,
        {
          id: location.id,
          name: location.name,
          address: location.address?.address1 ?? location.name,
        },
      ]);
      setIsLoading(false);
    }
  }, [userLocations]);

  return (
    <View style={{ margin: 'auto' }}>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          {stores.length > 0 && (
            <>
              <Text style={styles.text} selectable>
                {getText('store-location')}
              </Text>
              {stores.map((item, index) => (
                <StoreSelectorComponent
                  selected={selectedStore === item.id}
                  onValueChange={async () => {
                    setSelectedStore(item.id);
                    useAppStateStore.setState({
                      locationId: item.id,
                    });
                    navigation.navigate('dashboard'); // TODO: needs to be changed when the other views are ready
                  }}
                  pharmacyAddress={item.address}
                  pharmacyName={item.name}
                  key={index}
                />
              ))}
            </>
          )}
        </>
      )}
    </View>
  );
};

interface StoreSelectorComponent {
  pharmacyName: string;
  pharmacyAddress: string;
  selected: boolean;
  onValueChange: () => void;
}

const StoreSelectorComponent: FunctionComponent<
  PropsWithChildren<StoreSelectorComponent>
> = (props) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={props.selected ? styles.containerSelected : styles.container}>
      <View>
        <Text style={styles.title}>{props.pharmacyName}</Text>
        <Text style={styles.helperTitle}>{props.pharmacyAddress}</Text>
      </View>
      <View>
        <Button
          hierarchy={props.selected ? 'pharmacy-primary' : 'pharmacy-secondary'}
          onPress={() => props.onValueChange()}
          logger={{ id: 'select-location-button' }}
          style={{
            backgroundColor: theme.colors.pharmacyPrimary,
            borderColor: theme.colors.pharmacyPrimary,
          }}
        >
          {getText('select')}
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderColor: theme.palette.gray[200],
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    paddingHorizontal: '8px',
    background: theme.palette.gray[300],
    border: '1px solid #BCC8CE',
    borderRadius: theme.roundness,
    width: '474px',
    height: '60px',
    marginBottom: theme.getSpacing(1),
  },
  containerSelected: {
    borderColor: theme.palette.primary[300],
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    paddingHorizontal: '8px',
    backgroundColor: theme.palette.primary[50],
    border: '1px solid #77C7F6',
    borderRadius: theme.roundness,
    width: '474px',
    height: '60px',
    marginBottom: theme.getSpacing(1),
  },
  title: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'left',
    color: theme.palette.gray[700],
  },
  helperTitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'left',
    color: theme.palette.gray[600],
  },
  text: {
    fontSize: 32,
    marginBottom: theme.getSpacing(4),
  },
}));
