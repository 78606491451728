import { View, Text } from 'react-native';
import { useInboxState } from '../screens/messages/inbox-store/inbox-store';
import { makeStyles } from 'assets/theme';
import {
  setCounts,
  setPatients,
  setRawConversations,
  setViewedConversations,
} from '../screens/messages/inbox-store/inbox-actions';
import unifiedCommsService from '../api/UnifiedCommsService';
import { useEffect } from 'react';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  compare,
  groupConversationsByPatient,
} from '../screens/messages/utils';
import { useAppStateStore } from '../store/app-store';
import { useSockets } from '../screens/messages/hooks/useSockets';

export const MessageCount = (): JSX.Element | null => {
  useSockets();
  const { count, rawConversations, viewedConversations, conversationsSorting } =
    useInboxState();
  const { locationId, pharmacyId } = useAppStateStore();

  useEffect(() => {
    void (async () => {
      const rawConversationsData: DirectMessagePatientDto[] =
        await unifiedCommsService.getAllPatientsWithConversationsByLocation(
          pharmacyId,
          locationId,
        );

      const groupedConversations = groupConversationsByPatient(
        rawConversationsData,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          conversationsSorting.field,
          conversationsSorting.order,
          conversationsSorting.isDate,
        ),
      );
      const newViewedConversations = rawConversationsData
        .filter((conversation) => conversation.pharmacy_viewed_all_messages)
        .map((conversation) => conversation.conversation_id);

      setRawConversations(rawConversationsData);
      setPatients(groupedConversations);
      setViewedConversations(newViewedConversations);
    })();
  }, [locationId, pharmacyId]);

  useEffect(() => {
    const count: number = rawConversations.length - viewedConversations.length;
    setCounts({ unread: count });
  }, [viewedConversations, rawConversations]);

  const styles = useStyles();

  return count.unread ? (
    <View style={styles.container}>
      <Text>{count.unread}</Text>
    </View>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.getSpacing(1),
    borderRadius: 50,
    backgroundColor: '#FFC854',
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
