import { makeStyles, useTheme } from 'assets/theme';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View, TouchableOpacity, Image, Text } from 'react-native';
import {
  IDocumentModalState,
  IImageModalState,
  Attachment,
  Image as IImage,
} from '../types';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import ParsedText from 'react-native-parsed-text';
import {
  onUrlPress,
  onPhonePress,
  onEmailPress,
} from '../../../common/linking-utils';
import { PHONE_PATTERN } from '../data';
import { useInboxState } from '../inbox-store/inbox-store';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';

type IBubbleProps = {
  args: any;
  loading: boolean;
  setDocumentModalState: (arg: IDocumentModalState) => void;
  setImageModalState: (arg: IImageModalState) => void;
  lastRightMessageId: string | number | undefined;
};

const Bubble: FC<IBubbleProps> = ({
  args,
  loading,
  setImageModalState,
  setDocumentModalState,
  lastRightMessageId,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { selectedConversation, rawConversations } = useInboxState();

  if (!args.currentMessage || !args.user) {
    return null;
  }

  const status = useMemo(() => {
    if (lastRightMessageId !== args.currentMessage._id) {
      return null;
    }

    const conversation = rawConversations.find(
      (item) => item.conversation_id === selectedConversation?.conversation_id,
    );

    const read = conversation?.patient_viewed_all_messages;

    return <Text style={styles.statusMessage}>{read ? 'read' : 'sent'}</Text>;
  }, [lastRightMessageId, rawConversations]);

  const isPharmacist = args.currentMessage.author_type === AuthorType.Pharmacy;
  const attachments = args.currentMessage.attachments;
  const attachmentsPresent = Boolean(
    attachments?.images.length || attachments?.files.length,
  );

  if (loading) {
    return (
      <View style={{ flex: 1, marginBottom: 150, marginRight: 50 }}>
        <LoadingIndicator color={theme.palette.primary[700]} />
      </View>
    );
  }

  return (
    <View style={[styles.bubble, isPharmacist && styles.pharmacistBubble]}>
      <View
        style={[
          styles.bubbleContainer,
          isPharmacist
            ? { backgroundColor: theme.palette.primary[700] }
            : { marginLeft: 0, marginRight: 60 },
        ]}
      >
        <View>
          <Text
            style={[
              styles.bubbleText,
              attachmentsPresent && { paddingBottom: theme.getSpacing(1) },
              isPharmacist && { color: theme.palette.white },
            ]}
            selectable
          >
            <ParsedText
              parse={[
                {
                  type: 'url',
                  style: { color: theme.palette.success[300] },
                  onPress: onUrlPress,
                },
                {
                  pattern: PHONE_PATTERN,
                  style: { color: theme.palette.success[300] },
                  onPress: onPhonePress,
                },
                {
                  type: 'email',
                  style: { color: theme.palette.success[300] },
                  onPress: onEmailPress,
                },
              ]}
            >
              {args.currentMessage.text}
            </ParsedText>
          </Text>
          {attachmentsPresent && (
            <View style={{ flexDirection: 'column' }}>
              {Boolean(attachments?.files.length) && (
                <View style={{ flexDirection: 'column' }}>
                  {attachments.files.map((attachment: Attachment) => (
                    <View style={styles.wrapper} key={attachment.id}>
                      <TouchableOpacity
                        onPress={() =>
                          setDocumentModalState({
                            show: true,
                            stored_filename: attachment.stored_filename,
                            name: attachment.name,
                          })
                        }
                      >
                        <Text style={styles.fileWrapper}>
                          {attachment.name}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              )}
              {Boolean(attachments?.images.length) && (
                <View style={styles.imagesContainer}>
                  {attachments.images.map((attachment: IImage) => (
                    <View style={styles.wrapper} key={attachment.id}>
                      <TouchableOpacity
                        onPress={() =>
                          setImageModalState({
                            show: true,
                            stored_filename: attachment.stored_filename,
                            uri: attachment.url,
                          })
                        }
                      >
                        <Image
                          source={{ uri: attachment.url }}
                          style={styles.image}
                        />
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              )}
            </View>
          )}
        </View>
      </View>
      {status}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[100],
    padding: theme.getSpacing(1),
    borderRadius: 8,
    maxWidth: '90%',
  },
  bubble: {
    flex: 1,
    alignItems: 'flex-start',
  },
  pharmacistBubble: { alignItems: 'flex-end' },
  bubbleText: {
    ...theme.fonts.regular,
    color: theme.palette.black,
    overflowWrap: 'break-word',
  },
  image: {
    height: 100,
    width: 100,
    zIndex: 1,
  },
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fileWrapper: {
    color: theme.palette.cyan[200],
  },
  wrapper: {
    margin: theme.getSpacing(1),
  },
  statusMessage: {
    ...theme.fonts.regular,
    color: theme.palette.gray[500],
    fontSize: 12,
  },
}));

export default Bubble;
