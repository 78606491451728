import moment from 'moment';
import { IComposedAttachments, Order } from './types';
import {
  ClientAttachmentDto,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import fileStorageService from '../../api/FileStorageService';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import {
  ATTACHMENT_HEIGHT,
  ATTACHMENT_SIDE_PADDING,
  BUTTON_MARGIN,
  DEFAULT_NEW_LINES_COUNT,
  INPUT_TOOLBAR_MARGIN,
  LINE_HEIGHT,
  MAX_LINES_COUNT,
  MESSAGES_CONTAINER_HEIGHT,
  NEW_LINE_CHARACTER,
  TEXT_INPUT_TOOLBAR_HEIGHT,
} from './data';

export function compare(
  currentItem: any,
  nextItem: any,
  key: string,
  order: Order = Order.DESC,
  isDate = true,
) {
  let aCompared = isDate
    ? new Date(currentItem[key]).getTime()
    : currentItem[key];
  let bCompared = isDate ? new Date(nextItem[key]).getTime() : nextItem[key];
  aCompared =
    typeof aCompared === 'string' ? aCompared.toLowerCase() : aCompared;
  bCompared =
    typeof bCompared === 'string' ? bCompared.toLowerCase() : bCompared;
  const isDesc = order === Order.DESC;
  if (isDate) {
    const direction = isDesc ? bCompared - aCompared : aCompared - bCompared;
    return direction;
  } else {
    const direction = isDesc ? [-1, 1, 0] : [1, -1, 0];
    if (aCompared > bCompared) {
      return direction[0];
    } else if (aCompared < bCompared) {
      return direction[1];
    } else {
      return direction[2];
    }
  }
}

export function getFullName(user: any) {
  const { first_name, last_name, firstName, lastName } = user ?? {};
  if (first_name || last_name) {
    return first_name + ' ' + last_name;
  } else {
    return firstName + ' ' + lastName;
  }
}

// These rules apply strictly for past time
export const formatMessageDate = (
  inputDate: string,
  inputFormat?: string | undefined,
): string => {
  const dateDay = moment
    .utc(inputDate, inputFormat)
    .local()
    .format('MM/DD/YYYY');
  const dateDayFormatted = moment.utc(dateDay, 'MM/DD/YYYY').local();
  const localInputTime = moment.utc(inputDate, inputFormat).local();
  const localBaseTime = moment.utc().local();
  const diff = dateDayFormatted.diff(localBaseTime, 'days');
  const inputYear = localInputTime.year();
  const baseYear = localBaseTime.year();

  if (diff === 0) {
    // 3:50pm (if today)
    return localInputTime.format('h:mm a');
  } else if (diff > -8) {
    // 4d (if within the last week)
    return Math.abs(diff) + 'd';
  } else if (inputYear === baseYear) {
    // Dec 2, 10:06am (if this year, but not this week)
    return localInputTime.format('MMM D, h:mm a');
  } else {
    // 11/18/2021 10:43am (if not this year)
    return localInputTime.format('MM/DD/YYYY h:mm a');
  }
};

export const groupConversationsByPatient = (
  rawConversationsData: DirectMessagePatientDto[],
): DirectMessagePatientDto[] => {
  const patientGroupings: Record<string, DirectMessagePatientDto> = {};
  const finalPatientsList: DirectMessagePatientDto[] = [];
  rawConversationsData.forEach((conversation) => {
    const existingConversation =
      patientGroupings[conversation.location_patient_id];

    if (existingConversation) {
      const existingDate = new Date(
        existingConversation.most_recent_qualifying_message_date,
      );
      const conversationDate = new Date(
        conversation.most_recent_qualifying_message_date,
      );

      if (existingDate < conversationDate) {
        existingConversation.most_recent_qualifying_message =
          conversation.most_recent_qualifying_message;
        existingConversation.most_recent_qualifying_message_date =
          conversation.most_recent_qualifying_message_date;
      }

      if (!conversation.pharmacy_viewed_all_messages) {
        existingConversation.pharmacy_viewed_all_messages = false;
      }
    } else {
      patientGroupings[conversation.location_patient_id] = { ...conversation };
    }
  });

  for (const [key, value] of Object.entries(patientGroupings)) {
    finalPatientsList.push(value);
  }

  return finalPatientsList;
};

export async function composeAttachments(
  attachments: ClientAttachmentDto[] | undefined,
  locationId: string,
  pharmacyId: string,
): Promise<IComposedAttachments> {
  const attachmentsPresent = Boolean(attachments && attachments.length);

  if (!attachmentsPresent) {
    return {
      files: [],
      images: [],
    };
  }

  async function getAttachmentUrl(attachment: ClientAttachmentDto) {
    const urlResponse = await fileStorageService.readUrl(
      LocationCategory.DirectMessage,
      locationId,
      attachment.stored_filename,
      pharmacyId,
    );
    return {
      ...attachment,
      url: urlResponse.data.url,
    };
  }

  // This function separates images from simple files.
  // Further (in ChatBox component) they will be handled and showed a bit differently.
  function separateImages(files: ClientAttachmentDto[]) {
    return files.reduce(
      (
        previousElement: ClientAttachmentDto[][],
        element: ClientAttachmentDto,
      ) => {
        const [images, files] = previousElement;
        return fileStorageService.isImage(element.name)
          ? [[...images, element], files]
          : [images, [...files, element]];
      },
      [[], []],
    );
  }

  const [images, files] = separateImages(attachments!);
  const urlImages = images.length
    ? await Promise.all(images.map(getAttachmentUrl))
    : [];

  return {
    files: files,
    images: urlImages,
  };
}

export function getLinesCount(inputText: string) {
  return (
    (inputText.match(new RegExp(NEW_LINE_CHARACTER, 'g')) || []).length +
    DEFAULT_NEW_LINES_COUNT
  );
}

export function getChatSizes(inputText: string, attachmentsCount: number) {
  const linesCount: number =
    (inputText.match(new RegExp(NEW_LINE_CHARACTER, 'g')) || []).length +
    DEFAULT_NEW_LINES_COUNT;
  // if attachments exist, multiply count of attachments to height of single one
  // `ATTACHMENT_SIDE_PADDING * 2` - plus padding top, and plus padding bottom
  const attachmentsHeight = attachmentsCount
    ? attachmentsCount * ATTACHMENT_HEIGHT + ATTACHMENT_SIDE_PADDING * 2
    : 0;
  const actionsHeight = 32 + BUTTON_MARGIN; // 32 - default height of button
  const constantHeights =
    attachmentsHeight + actionsHeight + INPUT_TOOLBAR_MARGIN;

  if (linesCount >= MAX_LINES_COUNT) {
    const messagesContainerHeight =
      MESSAGES_CONTAINER_HEIGHT - MAX_LINES_COUNT * LINE_HEIGHT;
    const textInputToolbarHeight =
      TEXT_INPUT_TOOLBAR_HEIGHT + MAX_LINES_COUNT * LINE_HEIGHT;
    return {
      wholeContainerHeight:
        messagesContainerHeight + textInputToolbarHeight + constantHeights,
      messagesContainerHeight: messagesContainerHeight,
      textInputToolbarHeight: textInputToolbarHeight,
    };
  } else {
    const messagesContainerHeight =
      MESSAGES_CONTAINER_HEIGHT - linesCount * LINE_HEIGHT;
    const textInputToolbarHeight =
      TEXT_INPUT_TOOLBAR_HEIGHT + linesCount * LINE_HEIGHT;
    return {
      wholeContainerHeight:
        messagesContainerHeight + textInputToolbarHeight + constantHeights,
      messagesContainerHeight: messagesContainerHeight,
      textInputToolbarHeight: textInputToolbarHeight,
    };
  }
}
