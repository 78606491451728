import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';

export const AccumulativeInput: FunctionComponent<AccumulativeInputProps> = ({
  name,
  input,
  label,
  limit,
}) => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error('Accumulative Input must have a parent form context');
  }

  const { control, setValue, watch } = formContext;
  const fieldsArray = useFieldArray({
    control: control,
    name: name,
  });
  const styles = useStyles();
  const formValues = watch('forms');

  const firstFormElement =
    formValues?.length > 0 && formValues?.slice(0, 1).pop();

  const hasDeleteButton = formValues?.length > 1 || !!firstFormElement?.form_id;

  return (
    <>
      {label && <Text style={styles.label}>{label}</Text>}
      {fieldsArray.fields.map((data, index) => (
        <View style={styles.inputContainer}>
          <Controller
            control={control}
            name={`${name}.${index}`}
            render={({ field }) =>
              React.createElement(input, {
                ...field,
              })
            }
          />
          {hasDeleteButton ? (
            <Button
              hierarchy="pharmacy-secondary"
              size="small"
              icon={MinusCircleIcon}
              onPress={() => {
                fieldsArray.fields.length === 1
                  ? setValue('forms', [{}])
                  : fieldsArray.remove(index);
              }}
              logger={{ id: `remove-${name}-input-button` }}
              style={styles.button}
            >
              {getText('remove')}
            </Button>
          ) : null}

          {index + 1 === fieldsArray.fields.length &&
          (!limit || fieldsArray.fields.length < limit) ? (
            <Button
              hierarchy="pharmacy-secondary"
              size="small"
              icon={PlusCircleIcon}
              onPress={() => {
                fieldsArray.append({});
              }}
              logger={{ id: `add-${name}-input-button` }}
              style={styles.button}
            >
              {getText('add-another')}
            </Button>
          ) : null}
        </View>
      ))}
    </>
  );
};

export interface AccumulativeInputProps {
  name: string;
  input: FunctionComponent<PropsWithChildren<any>>;
  label?: string;
  limit?: number;
}

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
  },
  button: {
    borderWidth: 0,
    width: 'auto',
  },
  label: {
    marginBottom: theme.getSpacing(1),
    color: theme.palette.gray[700],
    fontSize: 14,
  },
}));
