import React from 'react';
import { TooltipProvider } from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import { AppLoader } from 'assets/common/AppLoader';
import { NavigationContainer } from './modules/common/NavigationContainer';
import { RootNavigation } from './modules/navigation/RootNavigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLoginState } from './modules/screens/login/login-store';
import { useUserState } from './modules/store/user-store';
import { StorageKeys } from './enums/storage-keys';
import { ThemeProvider } from 'assets/theme';
import { ApiConfig } from 'assets/core/api';
import { logout } from './modules/screens/login/login-actions';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';
import 'photoswipe/dist/photoswipe.css';

import { ProSidebarProvider } from 'react-pro-sidebar';

import './modules/common/react-toastify-overrides.css';
import './modules/common/photoswipe-overrides.css';
import './global-style-overrides.css';
import UsersService from './modules/api/UsersService';
import { useDetectPresence } from './modules/common/sessions/presence-tracking';

ApiConfig.setBaseApiConfig({
  getAccessToken: async () =>
    await AsyncStorage.getItem(StorageKeys.AccessToken),
  setAccessToken: async (token: string) =>
    await AsyncStorage.setItem(StorageKeys.AccessToken, token),
  signOut: async () => {
    await logout();
  },
  onError: async (error: any) => {
    // TODO
    console.error('error from ApiConfig.onError', error);
  },
  retryRefreshToken: async () => {
    const refreshToken = await AsyncStorage.getItem(StorageKeys.RefreshToken);
    const { access_token } = await UsersService.refreshToken({
      refresh_token: refreshToken ?? '',
    });
    return access_token;
  },
});

export default function App() {
  useDetectPresence();
  return (
    <SafeAreaProvider>
      <AppLoader onBeforeLoad={initialize}>
        <NavigationContainer>
          <ThemeProvider>
            <ProSidebarProvider>
              <TooltipProvider>
                <RootNavigation />
                <ToastContainer />
              </TooltipProvider>
            </ProSidebarProvider>
          </ThemeProvider>
        </NavigationContainer>
      </AppLoader>
    </SafeAreaProvider>
  );
}

const initialize = async () => {
  try {
    const value = await AsyncStorage.getItem(StorageKeys.AccessToken);
    const userId = await AsyncStorage.getItem(StorageKeys.UserId);

    if (value && userId) {
      const user = await UsersService.getPharmacistUser(userId);
      useUserState.setState({
        data: user,
      });
      useLoginState.setState({ status: 'success' });
    }
  } catch (e) {}
};
