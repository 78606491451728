import {
  AppointmentFilters,
  useAppointmentFiltersState,
} from './appointment-filters-store';

export const setAppointmentFilters = (filters: AppointmentFilters) => {
  useAppointmentFiltersState.setState({
    filters,
    showModal: false,
    isCustomFilter: !!Object.values(filters).filter(
      (filter) => filter !== undefined,
    ).length,
  });
};

export const updateAppointmentFiltersShowModalState = (newState: boolean) => {
  useAppointmentFiltersState.setState({
    showModal: newState,
  });
};
