import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  BottomSheetScrollView,
  BottomSheetView,
  BottomSheetModal,
  BottomSheetFooter,
  BottomSheetFooterProps,
} from '@gorhom/bottom-sheet';
import { PlatformType } from '../types';
import { Platform, ViewStyle } from 'react-native';
import { makeStyles } from '../../theme';
import { Text } from '../text';
import { SafeAreaView } from 'react-native-safe-area-context';

export const BottomSheet: FunctionComponent<
  PropsWithChildren<BottomSheetProps>
> = ({
  children,
  platform = Platform.OS,
  height = '40%',
  bottomSheetRef,
  onDismiss,
  enablePanDownToClose = true,
  title,
  headerContent,
  headerContentStyle,
  footerContent,
  footerContentStyle,
  contentContainerStyle,
  hideHandle = false,
}) => {
  const styles = useStyles();
  const snapPoints = useMemo(
    () =>
      height && Array.isArray(height)
        ? (height as string[])
        : ([height] as string[]),
    [height],
  );

  const footerComponentMemo = useMemo(() => {
    if (footerContent)
      return (props: BottomSheetFooterProps) => (
        <BottomSheetFooter
          style={{ ...styles.containerPaddings, ...footerContentStyle }}
          {...props}
        >
          <SafeAreaView>{footerContent}</SafeAreaView>
        </BottomSheetFooter>
      );
  }, [footerContent]);

  const insets = useSafeAreaInsets();

  return platform !== 'web' ? (
    <BottomSheetModal
      index={0}
      style={styles.root}
      ref={bottomSheetRef}
      snapPoints={snapPoints}
      enablePanDownToClose={enablePanDownToClose}
      onDismiss={onDismiss}
      footerComponent={footerComponentMemo}
      android_keyboardInputMode={
        platform === 'android' ? 'adjustResize' : undefined
      }
      keyboardBehavior={platform === 'ios' ? 'fillParent' : undefined}
      keyboardBlurBehavior={platform === 'ios' ? 'restore' : undefined}
      topInset={insets.top}
      handleComponent={hideHandle ? null : undefined}
    >
      {title && (
        <BottomSheetView
          testID={BottomSheetTestIDs.titleContent}
          style={[styles.containerPaddings]}
        >
          <Text style={styles.title}>{title}</Text>
        </BottomSheetView>
      )}

      {headerContent && (
        <BottomSheetView
          testID={BottomSheetTestIDs.headerContent}
          style={[styles.containerPaddings, headerContentStyle]}
        >
          {headerContent}
        </BottomSheetView>
      )}

      <BottomSheetScrollView
        testID={BottomSheetTestIDs.content}
        style={[styles.containerPaddings]}
        enableFooterMarginAdjustment
        contentContainerStyle={contentContainerStyle}
      >
        {children}
      </BottomSheetScrollView>
    </BottomSheetModal>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    shadowColor: theme.colors.backdrop,
    shadowOpacity: 0.1,
  },
  title: {
    alignSelf: 'center',
    fontSize: 18,
    fontWeight: '600',
    fontFamily: 'Lato_700Bold',
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  containerPaddings: {
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
}));
export interface BottomSheetProps {
  platform?: PlatformType;
  height?: string | string[];
  bottomSheetRef?: React.RefObject<BottomSheetModal>;
  onDismiss?: () => void;
  title?: string;
  footerContent?: React.ReactNode;
  footerContentStyle?: ViewStyle;
  headerContent?: React.ReactNode;
  headerContentStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  hideHandle?: boolean;
  enablePanDownToClose?: boolean;
}

export const BottomSheetTestIDs = {
  content: 'bottom-sheet-content',
  titleContent: 'bottom-sheet-title-content',
  headerContent: 'bottom-sheet-content',
};
