import type { IIdleTimer } from 'react-idle-timer';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import { logout } from '../../../modules/screens/login/login-actions';
import { useUserState } from '../../store/user-store';

const SEC_MS = 1e3;
const HOUR_SEC = 60 * 60;
const SOFT_IDLE_SEC = 30;
const HARD_IDLE_SEC = 4 * HOUR_SEC;

export const useDetectPresence = () => {
  const THROTTLE_MS = 1e4;
  const user = useUserState().data;
  const loggedIn = !!user?.id;

  const softLogout = () => {
    // STUB OUT - This will show the PIN screen
  };

  const onAction = () => {
    // STUB OUT - updateUsersActivity();
  };

  const hardLogout = () => {
    logout();
  };

  /**
   * Logs out the current active user
   * Leaves users auth info to use with PIN
   * Stub out
   */
  const softIdleTimer: IIdleTimer = useIdleTimer({
    disabled: !loggedIn,
    onIdle: softLogout,
    timeout: SOFT_IDLE_SEC * SEC_MS,
    throttle: THROTTLE_MS,
    onAction,
  });

  /**
   * Logs out all users
   * Todo, clear soft logout users session info
   */
  const hardIdleTimer = useIdleTimer({
    onIdle: hardLogout,
    timeout: HARD_IDLE_SEC * SEC_MS,
    throttle: THROTTLE_MS,
  });
  return { softIdleTimer, hardIdleTimer };
};
