import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View, Text } from 'react-native';
import { AlertTriangleIcon } from 'assets/icons';
import { Avatar } from 'assets/components/avatar';
import { makeStyles, useTheme } from 'assets/theme';
import useViewSidebarStore from './view-sidebar-store';
import { Icon } from 'assets/components/icon';
import { getText } from 'assets/localization/localization';
import { formatTimeDate, formatDate } from '../../common/datetime-utils';
import {
  PharmacySidebar,
  useStyles as usePharmacySidebarStyles,
} from '../../components/PharmacySidebar';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import NewChatButton from '../messages/components/new-chat-button/NewChatButton';
import { Alert } from 'assets/components/alert';
import { ResourceItem } from '../../api/MedicationService';
import {
  allergiesAreExplicitlyEmpty,
  findAllergiesById,
} from './medication-actions';

export type RefillDetailSidebarProps = {
  onPatientDetailsPress?: () => void;
};

const RefillDetailSidebar: FunctionComponent<
  PropsWithChildren<RefillDetailSidebarProps>
> = ({ onPatientDetailsPress }) => {
  const styles = useStyles();
  const theme = useTheme();
  const pharmacySidebarStyles = usePharmacySidebarStyles();
  const { sidebarDetails } = useViewSidebarStore();
  const [allergiesItems, setAllergiesItems] = useState<ResourceItem[]>();

  if (!sidebarDetails) return <>Loading...</>;
  if (!sidebarDetails.patientDetails || !sidebarDetails.prescriptionDetails)
    throw Error('Error Loading Data');

  const { prescriptionDetails, patientDetails } = sidebarDetails;

  const loadAllergiesDetails = async () => {
    const allergiesResponse = await findAllergiesById(
      patientDetails.allergies || [],
    );
    setAllergiesItems(allergiesResponse);
  };

  const getAllergiesText = () => {
    if (!patientDetails.allergies?.length) return 'Info not available';

    return allergiesItems?.map((a) => a.text).join(',');
  };

  const getAllergiesAlert = ():
    | {
        alertType: 'info' | 'warning';
        alertText: string;
      }
    | undefined => {
    const allergiesShowAlert = allergiesAreExplicitlyEmpty(
      patientDetails.allergies || [],
    );

    if (allergiesShowAlert) return undefined;

    const allergiesAlertType =
      patientDetails.allergies?.length === 0 ? 'info' : 'warning';
    const allergiesAlertText = !patientDetails.allergies?.length
      ? 'Allergy info not available'
      : `Allergies: ${allergiesItems?.map((a) => a.text).join(', ') || ''}`;

    return {
      alertType: allergiesAlertType,
      alertText: allergiesAlertText,
    };
  };

  useEffect(() => {
    void loadAllergiesDetails();
  }, [patientDetails]);

  function StyledPrescriptionStatus() {
    if (prescriptionDetails.refill_status !== RefillStatus.Accepted) {
      return (
        <View style={styles.prescriptionStatus}>
          <View style={{ marginRight: theme.getSpacing(1) }}>
            <Text style={{ color: theme.colors.error, fontWeight: '600' }}>
              {prescriptionDetails.refill_status?.replace('_', ' ')}
            </Text>
          </View>
          <View
            style={{
              justifyContent: 'center',
              marginRight: theme.getSpacing(1),
            }}
          >
            <Icon
              icon={AlertTriangleIcon}
              size={16}
              color={theme.colors.error}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View style={{ paddingBottom: theme.getSpacing(2) }}>
          <Text
            style={{ color: theme.palette.success[700], fontWeight: '600' }}
          >
            {getText('success')}
          </Text>
        </View>
      );
    }
  }

  function RefillDetailsContainer() {
    return (
      <View>
        <Text style={styles.refillNumber}>{prescriptionDetails.rx_number}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {getText('rx-number')}
        </Text>

        <View style={styles.chatButton}>
          <NewChatButton patient={patientDetails} />
        </View>

        <Text style={pharmacySidebarStyles.fieldLabel}>{getText('name')}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {prescriptionDetails.drug_name}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText('status')}
        </Text>
        <StyledPrescriptionStatus />

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText('submitted')}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {formatTimeDate(prescriptionDetails.submitted_at)}
        </Text>

        <View style={pharmacySidebarStyles.rowView}>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText('channel')}
            </Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {prescriptionDetails.ingress_method === 'ivr'
                ? prescriptionDetails.ingress_method.toUpperCase()
                : prescriptionDetails.ingress_method}
            </Text>
          </View>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText('delivery-method')}
            </Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {prescriptionDetails.fulfillment_method}
            </Text>
          </View>
        </View>

        <Text style={pharmacySidebarStyles.boldSubtext}>
          {getText('note-for-pharmacy-not-optional')}
        </Text>
        <Text style={pharmacySidebarStyles.comments}>
          {prescriptionDetails.notes}
        </Text>
      </View>
    );
  }

  function PatientDetailsContainer() {
    const patientFullName = [
      patientDetails.first_name + patientDetails.last_name,
    ].join(' ');

    const allergiesText = getAllergiesText();
    const allergiesAlert = getAllergiesAlert();

    return (
      <View>
        <View style={pharmacySidebarStyles.sectionHeader}>
          <Text style={pharmacySidebarStyles.sectionHeaderLabel}>
            {getText('patient-info')}
          </Text>
          <Text
            style={pharmacySidebarStyles.link}
            onPress={() => onPatientDetailsPress && onPatientDetailsPress()}
          >
            {getText('details')}
          </Text>
          {/* TODO: This link will go to the patient section with the patient selected, which is not yet implemented */}
        </View>

        {allergiesAlert && (
          <View>
            <Alert
              intent={allergiesAlert.alertType}
              title={allergiesAlert.alertText}
            />
          </View>
        )}

        <View style={styles.patientNameHeader}>
          <View>
            <Text style={styles.patientName}>{patientFullName}</Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {formatDate(patientDetails.date_of_birth)}
            </Text>
          </View>
          <Avatar
            name={patientDetails.first_name}
            size={48}
            color={theme.palette.gray[200]}
          />
        </View>

        <Text style={pharmacySidebarStyles.fieldLabel}>{getText('phone')}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {prettyFormat(patientDetails.phone)}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText('email-text')}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {patientDetails.email}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText('allergies')}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>{allergiesText}</Text>
      </View>
    );
  }

  return (
    <PharmacySidebar
      title={getText('refill-details')}
      dataExists={!!sidebarDetails}
    >
      <View>
        <RefillDetailsContainer />
        <PatientDetailsContainer />
      </View>
    </PharmacySidebar>
  );
};

const useStyles = makeStyles((theme) => ({
  refillNumber: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(0.5),
  },
  patientNameHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  patientName: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
    fontSize: 24,
    fontWeight: '600',
  },
  prescriptionStatus: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    paddingBottom: theme.getSpacing(2),
    textTransform: 'capitalize',
  },
  chatButton: {
    marginBottom: theme.getSpacing(2),
  },
}));

export default RefillDetailSidebar;
